import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
    Switch,
    Route,
    withRouter,
    Redirect
} from 'react-router-dom';

import Dashboard from './components/Dashboard';
import Plans from './components/Plans';
import Checkout from './components/Checkout';
import Domain from './components/Domain';
import CustomDomain from './components/Domain/CustomDomain';
import Support from './components/Support';
import HeaderNav from './components/shared/HeaderNav';
import CustomSendingDomain from './components/Domain/CustomSendingDomain';

import parentWindowDataChannel from './data/ParentWindowDataChannel';

function AppRoutes({
    updateTitle,
    history
}) {
    const [showNav, setShowNav] = useState(false);

    const handleShowNav = (show) => {
        setShowNav(show);
    };

    useEffect(() => {
        const handleRouteUpdate = (route) => {
            // we received a route update from Ghost Admin to, e. g. the checkout for a specific
            // product. This is where the redirect to it happens
            return history.push(route);
        };

        const unlisten = () => history.listen((location) => {
            parentWindowDataChannel.sendMessage({
                route: location.pathname
            });
        });

        parentWindowDataChannel.sendMessage({
            route: history.location.pathname
        });

        parentWindowDataChannel.subscribe('routeUpdate', handleRouteUpdate);

        return () => {
            unlisten();
        };
    }, [history]);

    return (
        <>
            <HeaderNav showNav={showNav} />
            <Switch>
                <Route exact path="/">
                    <Dashboard updateTitle={updateTitle} showNav={handleShowNav} />
                </Route>
                <Route exact path="/billing">
                    <Dashboard updateTitle={updateTitle} showNav={handleShowNav} />
                </Route>
                <Route exact path="/billing/plans">
                    <Plans updateTitle={updateTitle} showNav={handleShowNav} />
                </Route>
                <Route exact path="/plans">
                    <Plans updateTitle={updateTitle} showNav={handleShowNav} />
                </Route>
                <Route exact path="/support">
                    <Support updateTitle={updateTitle} showNav={handleShowNav} />
                </Route>
                <Route exact path="/domain">
                    <Domain updateTitle={updateTitle} showNav={handleShowNav} />
                </Route>
                <Route exact path="/domain/custom">
                    <CustomDomain updateTitle={updateTitle} showNav={handleShowNav} />
                </Route>
                <Route exact path="/domain/sending">
                    <CustomSendingDomain updateTitle={updateTitle} showNav={handleShowNav} />
                </Route>
                <Route exact path="/checkout/:id">
                    <Checkout updateTitle={updateTitle} showNav={handleShowNav} />
                </Route>
                <Route exact path="/billing/checkout/:id">
                    <Checkout updateTitle={updateTitle} showNav={handleShowNav} />
                </Route>
                <Route exact path="/checkout">
                    <Checkout updateTitle={updateTitle} showNav={handleShowNav} />
                </Route>
                <Route exact path="/billing/checkout">
                    <Checkout updateTitle={updateTitle} showNav={handleShowNav} />
                </Route>
                <Route path="*" render={(props) => {
                    const url = props.match.url;
                    const regex = /\/\/+/g;
                    if (url.match(regex)) {
                        const cleanUrl = url.replace(regex, '/');
                        return (<Redirect to={cleanUrl} />);
                    } else {
                        return (<Dashboard updateTitle={updateTitle} />);
                    }
                }} />
            </Switch>
        </>
    );
}

export default withRouter(AppRoutes);

AppRoutes.propTypes = {
    updateTitle: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        listen: PropTypes.func.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired
        }).isRequired
    }).isRequired
};
