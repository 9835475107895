import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import posthog from 'posthog-js';
import {PostHogProvider} from 'posthog-js/react';

const posthogKey = process?.env?.REACT_APP_POSTHOG_API_KEY || null;
if (posthogKey !== null) {
    posthog.init(posthogKey,
        {
            api_host: 'https://e.ghost.org/ph/ingest',
            ui_host: 'https://eu.posthog.com',
            session_recording: {
                recordCrossOriginIframes: true
            },
            loaded: function () {
                window.posthog = posthog;
            }
        }
    );
} 

ReactDOM.render(
    <React.StrictMode>
        <PostHogProvider client={posthog}>
            <App />
        </PostHogProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
