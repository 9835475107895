/**
 * @typedef {object} BaseProduct
 * @property {string} billing_period
 * @property {string} stripe_product_id
 */

/**
 * @describe Returns the auto price ID when available
 * @param {{baseProduct: BaseProduct, availableProducts: object[]}} props
 * @returns {string|null}
 */
export default function getAutoPriceId({baseProduct, availableProducts}) {
    let autoPriceId = null;

    if (!baseProduct || !availableProducts?.length) {
        return null;
    }

    if (baseProduct?.billing_period && baseProduct?.stripe_product_id) {
        const currentPeriod = baseProduct?.billing_period;

        // Check if the addon is available
        const currentProductAddons = availableProducts?.find(product => product.stripeId === baseProduct?.stripe_product_id)?.addons;

        // Find the matching Stripe Price id according to the billing period
        if (currentProductAddons?.length > 0) {
            const autoPriceAddonPriceId = currentProductAddons
                .find(addon => addon?.name?.toLowerCase()?.indexOf('autobilling') >= 0)?.prices
                .filter(price => price.billing_period === currentPeriod)?.[0]?.stripe_price_id;

            autoPriceId = autoPriceAddonPriceId ?? null;
        }
    }

    return autoPriceId;
}
