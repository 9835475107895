import {useState} from 'react';

export default function useCopyToClipboard() {
    const [copiedText, setCopiedText] = useState(null);

    // reset the copied text state after 2 seconds
    const reset = () => {
        setTimeout(() => {
            setCopiedText(null);
        }, 2000);
    };

    const copy = async (text) => {
        if (navigator?.clipboard) {
            // Try to save to clipboard then save it in the state if worked
            try {
                await navigator.clipboard.writeText(text);
                setCopiedText(text);
                reset();
                return true;
            } catch (error) {
                console.warn('Copy failed', error);
                setCopiedText(null);
                return false;
            }
        } else if (window?.clipboardData) {
            // Fallback for older browsers that support clipboardData
            try {
                window.clipboardData.setData('Text', text);
                setCopiedText(text);
                reset();
                return true;
            } catch (error) {
                console.warn('Copy failed', error);
                setCopiedText(null);
                return false;
            }
        } else {
            console.warn('Clipboard not supported');
            return false;
        }
    };

    return [copiedText, copy];
}
