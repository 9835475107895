import {findSuitableProduct} from './billing-utils';

/**
 * @typedef {import('./billing-utils').SiteLimits} SiteLimits
 * @typedef {import('./billing-utils').FormattedProduct} FormattedProduct
 */

/**
 * @typedef {object} PendingSubscription
 * @property {string} product
 * @property {string} period
 * @property {string} priceId
 * @property {string} group
 */

/**
 * @typedef {object} CheckoutRouteProps
 * @property {SiteLimits} currentSiteLimits
 * @property {object} subscription
 * @property {object} user
 * @property {boolean} isTrial
 * @property {FormattedProduct[]} availableProducts
 * @property {PendingSubscription} pendingSubscription
 */

/**
 * @description Get the checkout route based on the current site limits and the available
 * products. We assume that there's a pending subscription update when we find the localStorage
 * item for it. Here we're setting the correct checkout route for the planned change
 * and also set an pendingSubscription Object to be able to mention the desired plan
 * and period to the customer.
 * @param {CheckoutRouteProps} props
 * @returns {string}
 */
export default function getCheckoutRoute(props) {
    const {
        currentSiteLimits,
        subscription,
        user,
        isTrial,
        availableProducts,
        pendingSubscription
    } = props;
    /** @type {string} */
    const showProductGroup = user?.show_product_group;
    /** @type {string} */
    const entryProduct = availableProducts?.[0]?.product?.name?.toLowerCase() ?? 'starter';
    /** @type {string} */
    const baseProduct = subscription?.meta?.baseProduct;
    /** @type {string} */
    let product = isTrial ? entryProduct : baseProduct?.name.toLowerCase() ?? entryProduct;
    /** @type {string} */
    let period = isTrial ? 'year' : baseProduct?.billing_period ?? 'year';
    /** @type {string} */
    let group = baseProduct?.group ?? showProductGroup;

    if (pendingSubscription) {
        return `/checkout/${pendingSubscription.product.toLowerCase()}-${pendingSubscription.group}-${pendingSubscription.period}`;
    } else {
        const suitableProduct = findSuitableProduct(availableProducts, currentSiteLimits, period, showProductGroup);

        if (suitableProduct?.product && suitableProduct?.price) {
            product = suitableProduct.product.name.toLowerCase();
            period = suitableProduct.price.billing_period;
            group = suitableProduct.product.group;
        }

        return `/checkout/${product}-${group}-${period}`;
    }
}
