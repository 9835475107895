import React, {useState} from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

import {cancelAtPeriodEnd} from '../../data/api';
import SpinnerButton from '../shared/SpinnerButton';

/**
 * @typedef {object} CancelAtPeriodEndWarningProps
 * @property {boolean} willCancelAtPeriodEnd
 * @property {string} currentPeriodEnd
 * @property {function} closeAndReload
 * @property {string} subscriptionId
 */

/**
 *
 * @param {CancelAtPeriodEndWarningProps} props
 * @returns {JSX.Element}
 */
function CancelAtPeriodEndWarning({
    willCancelAtPeriodEnd,
    currentPeriodEnd,
    closeAndReload,
    subscriptionId
}) {
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);

    const formattedDate = currentPeriodEnd ? new Date(currentPeriodEnd).toLocaleDateString('en-US', {month: 'long', day: 'numeric', year: 'numeric'}) : null;

    let warningContent = null;

    if (!willCancelAtPeriodEnd) {
        return null;
    }

    const handleContinueSubscription = async () => {
        setErrors(null);

        try {
            // controls the spinner button state
            setLoading(true);

            const res = await cancelAtPeriodEnd({subscriptionId, continue: true});

            setLoading(false);

            if (res?.errors) {
                Sentry.addBreadcrumb({
                    category: 'cancelAtPeriodEnd',
                    message: `Failed to reactivate subscription with ${res.errors}`,
                    level: 'info'
                });
                setErrors('Something went wrong. Please try again.');

                return;
            }

            // controls the parent component loading state
            closeAndReload({reload: true, delay: 0});
        } catch (error) {
            Sentry.captureException(error, {tags: {pointer: 'handleContinueSubscription'}});
            setErrors('Something went wrong. Please try again.');
            setLoading(false);
        }
    };

    if (!formattedDate) {
        warningContent = (
            <div>
                <p className="red">You've canceled your subscription</p>
                <p>Your site will go offline at the end of the billing period</p>
            </div>
        );
    } else {
        warningContent = (
            <div>
                <p className="red">You've canceled your subscription</p>
                <p>Your site will go offline on <strong>{formattedDate}</strong></p>
            </div>
        );
    }

    return (
        <div className="cancel-warning">
            <div className="cancel-warning-content">
                {warningContent}
                <div className="cancel-action">
                    {loading ?
                        <SpinnerButton
                            className="gh-btn gh-btn-block spinner"
                            html="<span><div class='gh-spinner'></div></span>"
                            disabled={true}
                        />
                        :
                        <SpinnerButton
                            className="gh-btn gh-btn-block"
                            html={errors ? 'Try again' : 'Reactivate subscription'}
                            onClick={handleContinueSubscription}
                        />
                    }
                </div>
            </div>
            <p className="error">{errors}</p>
        </div>
    );
}

CancelAtPeriodEndWarning.propTypes = {
    currentPeriodEnd: PropTypes.string,
    willCancelAtPeriodEnd: PropTypes.bool
};

export default CancelAtPeriodEndWarning;
