import {useState, useCallback} from 'react';
import {fetchPaymentMethod, changeOrActivateSubscriptionPlan} from '../data/api';

const sleep = (ms) => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
};

/**
 * @description Custom hook to toggle auto billing mode
 * @param {{id: string, customer_id: string, status: string, meta: {baseProduct: {stripe_price_id: string}, addons: {stripe_price_id: string}[]}}} subscription
 * @param {{id: string, stripe_token: string}} user
 * @param {string} autoPriceId - Stripe price ID for auto billing price
 * @param {'auto'|'manual'} mode - flag to toggle auto billing mode
 * @returns {{handleToggleAutoBilling: function, status: string, error: string}}
 */
const useToggleAutoBilling = (subscription, user, autoPriceId, mode) => {
    const [status, setStatus] = useState('idle');
    const [error, setError] = useState(null);

    const handleToggleAutoBilling = useCallback(async () => {
        setStatus('pending');
        setError(null);

        try {
            const addonIds = [];

            // Get the list of current addons from the subscription
            // and remove the auto price ID from the list
            if (subscription?.meta?.addons) {
                subscription.meta.addons.forEach((addon) => {
                    if (addon.stripe_price_id === autoPriceId) {
                        return;
                    }
                    addonIds.push(addon.stripe_price_id);
                });
            }

            // If mode is auto, add the auto price ID to the list of addons
            // If mode is manual, remove the auto price ID from the list of addons
            if (mode === 'auto') {
                addonIds.push(autoPriceId);
            } else {
                const index = addonIds.indexOf(autoPriceId);
                if (index > -1) {
                    addonIds.splice(index, 1);
                }
            }

            const data = {
                customerId: subscription.customer_id || user.stripe_token,
                subscriptionId: subscription.id,
                planId: subscription.meta.baseProduct.stripe_price_id,
                addonIds,
                isTrial: false,
                userId: user.id
            };

            // Fetch the payment method for the user, as we need the payment method ID
            // to update the subscription
            const {paymentMethodId, errors: paymentErrors} = await fetchPaymentMethod(data);

            if (!paymentErrors && paymentMethodId) {
                data.paymentMethodId = paymentMethodId;

                const billingDataResponse = await changeOrActivateSubscriptionPlan(data);

                // Ignore errors from fetching invoices, as they're not crucial to the flow and
                // can be caused by the site not being back up yet.
                if (billingDataResponse?.errors && billingDataResponse?.errors?.message?.indexOf('Error fetching invoices') < 0) {
                    setError(billingDataResponse?.errors?.message || 'Could not update billing mode. Please contact support@ghost.org');
                    if (status !== 'failed') {
                        setStatus('failed');
                    }
                } else {
                    if (process?.env?.NODE_ENV !== 'test') {
                        // Handle success with enough delay to let Daisy and Stripe sync
                        // the changes and restart the site in the background with the new
                        // limits applied to the config.
                        await sleep(7000);
                    }

                    setStatus('succeeded');
                }
            } else {
                setError(paymentErrors?.message || 'Error fetching payment method');
                if (status !== 'failed') {
                    setStatus('failed');
                }
            }
        } catch (err) {
            setError(err?.message || 'Error toggling auto billing');
            if (status !== 'failed') {
                setStatus('failed');
            }
        }
    }, [subscription, user, autoPriceId, mode, status, setStatus, setError]);

    return {handleToggleAutoBilling, status, error};
};

export default useToggleAutoBilling;
