import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import {
    Elements,
    useStripe,
    useElements,
    CardElement
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {
    updateCreditCard,
    setupIntent
} from '../../data/api';

import SpinnerButton from '../shared/SpinnerButton';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process?.env?.REACT_APP_STRIPE_PK);

const CARD_ELEMENT_FONTS = [
    {
        family: 'Inter',
        weight: 400,
        src: 'local("Inter var"), local("Inter"), url(/assets/fonts/Inter-roman.var.woff2) format("woff2")'
    }
];

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#15171A',
            fontWeight: '400',
            fontFamily: 'Inter, sans-serif',
            fontSize: '15px',
            fontSmoothing: 'antialiased',
            textShadow: '#000000',
            '::placeholder': {
                color: '#a9b6bc'
            },
            ':focus::placeholder': {
                color: '#a9b6bc'
            },
            ':-webkit-autofill': {
                color: '#687e87',
                fontWeight: '400',
                fontFamily: 'Inter, sans-serif',
                fontSize: '15px',
                fontSmoothing: 'antialiased',
                textShadow: '#000000'
            }
        },
        invalid: {
            color: '#687e87'
        }
    },
    classes: {
        invalid: 'error',
        complete: 'complete',
        empty: 'empty',
        focus: 'focus',
        webkitAutofill: 'autofill'
    }
};

function CardSection({disabled}) {
    return (
        <>
            <header>
                <h1>Update your card details.</h1>
            </header>
            <div className="gh-input-group">
                <label htmlFor="signup-name">Full name</label>
                <input
                    id="signup-name"
                    className="gh-input"
                    type="text"
                    required
                    name="name"
                    placeholder="Jamie Larson"
                    autoCorrect="off"
                    autoFocus
                    disabled={disabled}
                />
            </div>
            <div className="gh-input-group">
                <label htmlFor="signup-form-cc">Credit or debit card</label>
                <CardElement className="gh-input" options={CARD_ELEMENT_OPTIONS} />
            </div>
        </>
    );
}

CardSection.propTypes = {
    disabled: PropTypes.bool
};

function CardSetupForm(props) {
    const buttonStates = {
        success: {
            className: 'gh-btn gh-btn-block',
            html: '&#10004; Update complete!',
            type: 'submit',
            disabled: true
        },
        fail: {
            className: 'gh-btn gh-btn-block',
            html: 'Try again',
            type: 'submit',
            disabled: false
        },
        pending: {
            className: 'gh-btn gh-btn-block spinner',
            html: '<span><div class="gh-spinner"></div></span>',
            disabled: true
        },
        default: {
            className: 'gh-btn gh-btn-block',
            html: 'Update',
            type: 'submit',
            disabled: false
        }
    };

    const stripe = useStripe();
    const elements = useElements();

    const [errors, setErrors] = useState(null);
    const [buttonState, setButtonState] = useState({...buttonStates.default});

    const onSetErrors = message => setErrors(message);

    useEffect(() => {
        const scope = Sentry.getCurrentScope();
        Sentry.setUser({id: props?.userId, customerId: props?.customerId});
        Sentry.setTag('section', 'update-card');
        scope.setTransactionName('Update Card Modal');
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        setButtonState({...buttonStates.pending});
        props?.setModalLoadingState(true);
        onSetErrors(null);

        if (!stripe || !elements) {
            props?.setModalLoadingState(false);
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return setButtonState({...buttonStates.default, disabled: true});
        }

        const {clientSecret, setupIntentId, errors: stripeErrors} = await setupIntent(props.customerId);

        if (!stripeErrors && clientSecret) {
            const result = await stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement)
                }
            });

            if (result?.error) {
                props?.setModalLoadingState(false);
                Sentry.setContext('Stripe Confirm Card Result', {
                    setupIntentId,
                    result,
                    stripeError: result.error?.message,
                    tags: {pointer: 'stripe_confirmCardSetup'}
                });
                Sentry.captureMessage(result.error?.message);

                Sentry.withScope((scope) => {
                    scope.setExtra('stripeResult', result);
                    scope.setContext('Stripe Confirm Card Result');
                    scope.setExtra('setupIntentId', setupIntentId);
                    scope.setUser({userId: props.userId, customerId: props.customerId});
                    scope.setTag('pointer', 'stripe_confirmCardSetup');
                    scope.setLevel('warning');
                    Sentry.captureMessage(result.error || 'Error updating your card. Refresh the page and try again or contact support@ghost.org if the error persists.');
                });

                onSetErrors(result?.error?.message || 'Error updating your card. Refresh the page and try again or contact support@ghost.org if the error persists.');
                return setButtonState({...buttonStates.fail});
            } else {
                const res = await updateCreditCard({userId: props.userId, customerId: props.customerId, setupIntentId});

                if (res?.errors) {
                    props?.setModalLoadingState(false);

                    Sentry.withScope((scope) => {
                        scope.setExtra('stripeResult', result);
                        scope.setContext('Stripe Confirm Card API Result');
                        scope.setExtra('setupIntentId', setupIntentId);
                        scope.setUser({userId: props.userId, customerId: props.customerId});
                        scope.setTag('pointer', 'api_updateCreditCard');
                        Sentry.captureException(res?.errors?.message || 'Error updating credit card in API');
                    });

                    onSetErrors('Error updating your card. Refresh the page and try again or contact support@ghost.org if the error persists.');
                    return setButtonState({...buttonStates.fail});
                }

                // SUCCESS!
                props?.setModalLoadingState(false);
                setButtonState({...buttonStates.success});
                return props.onCloseModal({reload: false, delay: 1000});
            }
        } else {
            props?.setModalLoadingState(false);

            Sentry.withScope((scope) => {
                scope.setContext('Stripe SetupIntent Error');
                scope.setExtra('setupIntentId', setupIntentId);
                scope.setUser({userId: props.userId, customerId: props.customerId});
                scope.setTag('pointer', 'stripe_setupIntent');
                Sentry.captureException(stripeErrors);
            });

            onSetErrors(stripeErrors?.message || 'Error updating your card. Refresh the page and try again or contact support@ghost.org if the error persists.');
            return setButtonState({...buttonStates.fail});
        }
    };

    return (
        <form className="gh-form" onSubmit={handleSubmit}>
            <CardSection disabled={buttonState.disabled} />
            <SpinnerButton {...buttonState} data-test-btn="update-card" />
            {errors &&
                <div className="form-error">
                    <p className="error-msg">{errors}</p>
                </div>
            }
        </form>

    );
}

CardSetupForm.propTypes = {
    customerId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    setModalLoadingState: PropTypes.func,
    onCloseModal: PropTypes.func.isRequired
};

export default function StripeUpdateCard(props) {
    return (
        <Elements stripe={stripePromise} fonts={CARD_ELEMENT_FONTS}>
            <CardSetupForm {...props} />
        </Elements>
    );
}

StripeUpdateCard.propTypes = {
    customerId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    setModalLoadingState: PropTypes.func,
    onCloseModal: PropTypes.func.isRequired
};
