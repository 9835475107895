import formatNumber from './format-number';

const _checkLimits = (limits, currentSiteLimits, product) => {
    let isValid = [];

    limits.forEach((limit) => {
        if (currentSiteLimits[limit.name]) {
            const currentLimit = currentSiteLimits[limit.name];

            // compare allowed limit of product and current usage
            switch (limit?.name) {
            case 'staff':
                isValid.push({
                    limit: limit.name,
                    valid: product.toLowerCase() === 'business' ? true : currentLimit.total <= limit.value,
                    message: product.toLowerCase() === 'business' ? null : currentLimit.total > limit.value
                        ? `Not available with more than ${formatNumber(limit.value)} staff ${limit.value > 1 ? 'users' : 'user'}`
                        : null
                });
                break;
            case 'newsletters':
                isValid.push({
                    limit: limit.name,
                    valid: currentLimit.total <= limit.value,
                    message: currentLimit.total > limit.value
                        ? limit.value === 1 ? 'Not available with multiple newsletters' : `Not available with more than ${formatNumber(limit.value)} ${limit.value > 1 ? 'newsletters' : 'newsletter'}`
                        : null
                });
                break;
            case 'customThemes':
                isValid.push({
                    limit: limit.name,
                    valid: limit.value.toLowerCase().indexOf('official') >= 0 && currentLimit.hasOfficialTheme,
                    message: limit.value.toLowerCase().indexOf('official') >= 0 && !currentLimit.hasOfficialTheme
                        ? `Not available when using custom theme`
                        : null
                });
                break;
            case 'customIntegrations':
                // TODO: check custom integrations
                isValid.push({
                    limit: limit.name,
                    valid: true
                });
                break;
            case 'members':
                isValid.push({
                    limit: limit.name,
                    valid: currentLimit.total <= limit.value,
                    message: currentLimit.total > limit.value
                        ? `Not available with more than ${formatNumber(limit.value)} members`
                        : null
                });
                break;
            default:
                break;
            }
        }
    });

    return isValid;
};

const checkLimits = (price, {productLimits, currentSiteLimits, product}) => {
    let isValidProduct = [];
    let isValidPrice = [];

    if (!currentSiteLimits) {
        throw new Error('currentSiteLimits missing');
    }

    // check product limits (staff, customThemes, customIntegrations)
    if (productLimits && productLimits.length) {
        isValidProduct = _checkLimits(productLimits, currentSiteLimits, product);
    }

    // check price limits (members)
    if (price && price.limits && price.limits.length) {
        isValidPrice = _checkLimits(price.limits, currentSiteLimits);
    }

    const isValid = isValidProduct.concat(isValidPrice);

    const invalidLimits = isValid.filter(limit => ((limit.valid === false) && limit.message));

    return invalidLimits;
};

export default checkLimits;
