import checkLimits from './check-limits';
import formatNumber from './format-number';
import {findNearestLimit, getIncrementSteps} from './member-limits';
import {monthlyPrice} from './price-for-period';

/**
 * @typedef {object} AddonPrice
 * @property {string} billing_period
 * @property {number} cost
 * @property {string[]} limits
 * @property {string} stripe_price_id
 */

/**
 * @typedef {object} Addon
 * @property {string} name
 * @property {AddonPrice[]} prices
 */

/**
 * @typedef {object} Limit
 * @property {string} name
 * @property {number} value
 */

/**
 * @typedef {object} SiteLimitProperties
 * @property {boolean} exceeded
 * @property {number} limit
 * @property {number?} total
 * @property {string?} active
 * @property {boolean?} hasOfficialTheme
 */

/**
 * @typedef {Object.<string, any>} SiteLimitsKey
 * @typedef {SiteLimitsKey & SiteLimitProperties} SiteLimits
 */

/**
 * @typedef {string[]} Features
 */

/**
 * @typedef {object} Price
 * @property {number} cost
 * @property {string} stripe_price_id
 * @property {string} billing_period
 * @property {boolean} active
 * @property {Limit[]} limits
 * @property {string} nickname
 * @property {Features} features
 */

/**
 * @typedef {object} ProcessAddonsOptions
 * @property {Addon[]} addons
 * @property {boolean} isSubdirectory
 * @property {string} showPeriod
 */

/**
 * @typedef {object} GetPriceAndLimitsOptions
 * @property {Price[]} suitablePrices
 * @property {SiteLimits} currentSiteLimits
 * @property {string} productName
 * @property {Limit[]} limits
 */

/**
 * @typedef {object} GetFeaturesOptions
 * @property {Price} suitablePrice
 * @property {number} maxMembersForPrice
 * @property {Features} features
 * @property {boolean} isLastStepAndNotEntryProduct
 * @property {string} productName
 * @property {Price[]} prices
 * @property {boolean} showPeriod
 * @property {AddonPrice[]} availableAddonPrices
 * @property {boolean} isSubdirectory
 * @property {object[]} availableProducts
 */

/**
 * @typedef {object} ShouldDisableProductOptions
 * @property {Price} suitablePrice
 * @property {{base_product: string, stripe_price_id: string}} currentPrice
 * @property {Price[]} prices
 * @property {boolean} showPeriod
 * @property {boolean} isSubdirectory
 * @property {string} currentGhostVersion
 * @property {string} productName
 * @property {{message: string, limit: number}[]} limitsValidations
 * @property {AddonPrice[]} availableAddonPrices
 * @property {boolean} hasCustomSendingDomain
 * @property {object[]} availableProducts
 */

/**
 * @typedef {object} CalculateTotalCostOptions
 * @property {Price} suitablePrice
 * @property {boolean} showPeriod
 * @property {AddonPrice[]} availableAddonPrices
 * @property {string} productName
 * @property {boolean} isSubdirectory
 * @property {Price[]} prices
 * @property {boolean} _verbose
 */

/**
 * @typedef {object} IProductUtils
 * @property {(ProcessAddonsOptions) => {name: string, cost: number}[]|[]} processAddons
 * @property {(GetPriceAndLimitsOptions) => {suitablePrice: Price|null, limitsValidations: {message: string, limit: number}[]}} getPriceAndLimits
 * @property {({prices: Price[], showPeriod: boolean}) => Price|null} getFallbackPrice
 * @property {({price: Price, features: Features, isLastStepAndNotEntryProduct: boolean}) => Features} sortFeatures
 * @property {(GetFeaturesOptions) => {productFeatures: Features, addonFeatures: Features}} getFeatures
 * @property {({currentMembers: number, productName: string}) => boolean} isLastStepAndNotEntryProduct
 * @property {({currentMembers: number}) => boolean} isLastIncrementStep
 * @property {(ShouldDisableProductOptions) => {disableProduct: boolean, validationMsg: string|null}} shouldDisableProduct
 * @property {(CalculateTotalCostOptions) => string} calculateTotalCost
 */

/** @implements {IProductUtils} */
class ProductUtils {
    /**
     * @private
     * @param {{addons: Addon[], name: string, enforce?: boolean, period: string}} options
     * @returns {{name: string, price: string}|null}
     */
    #getAddonPriceAndName = ({
        addons,
        name,
        enforce = false,
        period
    }) => {
        const addonItem = addons
            .filter(addon => addon.name.toLowerCase().indexOf(name) >= 0)?.[0] ?? null;

        if (addonItem && enforce) {
            const addonPrice = addonItem.prices
                .filter(price => price.billing_period === period)?.[0] ?? null;

            if (addonPrice) {
                return {
                    name: addonItem.name,
                    price: addonPrice.cost
                };
            }
        }

        return null;
    }

    /**
     * @param {ProcessAddonsOptions} options
     * @returns {{name: string, cost: number}[]|[]} availableAddonPrices
     */
    processAddons = ({
        addons,
        isSubdirectory,
        showPeriod
    }) => {
        const subdirectoryAddon = this.#getAddonPriceAndName({
            addons,
            name: 'subdirectory',
            enforce: isSubdirectory,
            period: showPeriod
        });

        let availableAddonPrices = [];

        if (subdirectoryAddon) {
            availableAddonPrices.push(subdirectoryAddon);
        }

        return availableAddonPrices;
    };

    /**
     * @param {GetPriceAndLimitsOptions} options
     * @returns {{suitablePrice: Price|null, limitsValidations: {message: string, limit: number}[]}}
     */
    getPriceAndLimits = ({
        suitablePrices,
        currentSiteLimits,
        productName,
        limits
    }) => {
        // The prices are already sorted by member limits, so we can safely pick the first one
        const suitablePrice = suitablePrices?.[0] ?? null;

        // Check any suitable price against limits that would affect the price
        const limitsValidations = checkLimits(suitablePrice, {
            currentSiteLimits,
            product: productName,
            productLimits: limits
        });

        return {
            suitablePrice,
            limitsValidations
        };
    };

    /**
     * @param {{prices: Price[], showPeriod: boolean}} options
     * @returns {Price|null}
     */
    getFallbackPrice = ({prices, showPeriod}) => {
        return prices
            .filter(price => price.billing_period === showPeriod)
            .sort(({limits: a}, {limits: b}) => {
                const [aMembers] = a.filter(limit => limit.name === 'members');
                const [bMembers] = b.filter(limit => limit.name === 'members');

                return bMembers.value - aMembers.value;
            })[0] ?? null;
    };

    /**
     * @param {{price: Price, features: Features, isLastStepAndNotEntryProduct: boolean, availableProducts: object[]}} options
     * @returns {Features}
     */
    sortFeatures = ({
        price,
        features,
        isLastStepAndNotEntryProduct,
        productName,
        availableProducts
    }) => {
        const entryProduct = availableProducts?.[0]?.name?.toLowerCase();

        price?.features?.map((feature) => {
            if (feature.indexOf('members') >= 0) {
                if (isLastStepAndNotEntryProduct) {
                    const incrementSteps = getIncrementSteps(availableProducts);
                    feature = formatNumber(incrementSteps[incrementSteps.length - 1]) + '+ members';
                }

                if (productName) {
                    if (productName?.toLowerCase() !== entryProduct) {
                        // put members price feature on second place in the list
                        features.splice(1, 0, feature);
                    } else {
                        // put members price feature on first place in the list for Starter
                        features.splice(0, 0, feature);
                    }
                } else {
                    // put members price feature on second place in the array
                    features.splice(1, 0, feature);
                }
            } else {
                features.push(feature);
            }
            return features;
        });

        return features;
    };

    /**
     * @param {GetFeaturesOptions} options
     * @returns {{productFeatures: Features, addonFeatures: Features}}
     */
    getFeatures = ({
        suitablePrice,
        maxMembersForPrice,
        features,
        isLastStepAndNotEntryProduct,
        productName,
        prices,
        showPeriod,
        availableAddonPrices,
        isSubdirectory,
        availableProducts
    }) => {
        let productFeatures = features?.map(feature => feature);
        /** @type {Feature} */
        let addonFeatures = [];
        const fallBackPrice = this.getFallbackPrice({prices, showPeriod});

        if (suitablePrice && maxMembersForPrice) {
            productFeatures = this.sortFeatures({
                price: maxMembersForPrice,
                features: productFeatures,
                isLastStepAndNotEntryProduct,
                productName,
                availableProducts
            });
        } else if (fallBackPrice) {
            productFeatures = this.sortFeatures({
                price: fallBackPrice,
                features: productFeatures,
                isLastStepAndNotEntryProduct,
                productName,
                availableProducts
            });
        }

        if (isSubdirectory && availableAddonPrices?.length) {
            availableAddonPrices.forEach((addon) => {
                // We currently only support those three addons
                const addonFeatureName = addon?.name?.toLowerCase().indexOf('subdirectory') >= 0
                    && 'Managed subdirectory install';

                addonFeatures.push(addonFeatureName);
            });
        }

        return {
            productFeatures,
            addonFeatures
        };
    }

    /**
     * @param {{currentMembers: number, productName: string, availableProducts: object[]}} options
     * @returns {boolean}
     */
    isLastStepAndNotEntryProduct({currentMembers, productName, availableProducts}) {
        const isLastIncrementStep = this.isLastIncrementStep({currentMembers, availableProducts});
        // Products are sorted by order, with the lowest (entry) product first
        return isLastIncrementStep && availableProducts[0].name?.toLowerCase() !== productName?.toLowerCase();
    }

    /**
     * @param {{currentMembers: number, availableProducts: object[]}} options
     * @returns {boolean}
     */
    isLastIncrementStep({currentMembers, availableProducts}) {
        const incrementSteps = getIncrementSteps(availableProducts);
        return findNearestLimit(currentMembers, availableProducts) === incrementSteps[incrementSteps.length - 1];
    }

    /**
     * @param {ShouldDisableProductOptions} options
     * @returns {{disableProduct: boolean, validationMsg: string|null}}
     */
    shouldDisableProduct({
        suitablePrice,
        currentPrice,
        prices,
        showPeriod,
        isSubdirectory,
        currentGhostVersion,
        productName,
        limitsValidations,
        availableAddonPrices,
        hasCustomSendingDomain,
        availableProducts
    }) {
        let validationMsg = null;
        let shouldDisableProduct = false;
        const currentPriceId = currentPrice?.stripe_price_id;
        const entryProductName = availableProducts?.[0]?.name?.toLowerCase();

        if (suitablePrice && currentPriceId === suitablePrice?.stripe_price_id) {
            // Disable the price the user is currently subscribed to
            shouldDisableProduct = true;
            validationMsg = `This is your current plan and billing period`;
        } else if (!suitablePrice && prices?.length) {
            shouldDisableProduct = true;
            const fallBackPrice = this.getFallbackPrice({prices, showPeriod});

            // Set a validation message like we do in checkLimits, so we're consistent
            validationMsg = `Not available with more than ${formatNumber(fallBackPrice?.limits[0].value)} members`;
        }

        if (isSubdirectory && !availableAddonPrices?.length) {
            shouldDisableProduct = true;
            validationMsg = isSubdirectory
                ? `Not available with active subdirectory`
                : ``;
        }

        // Disable Starter plan for Ghost version before 4.x
        if (currentGhostVersion?.major < 4 && productName.toLowerCase() === entryProductName) {
            shouldDisableProduct = true;
            validationMsg = `Not available with older Ghost versions`;
        }

        if (hasCustomSendingDomain && productName.toLowerCase() === entryProductName && currentPrice?.base_product.toLowerCase() !== entryProductName) {
            shouldDisableProduct = true;
            validationMsg = `Not available with active custom sending domain`;
        }

        // Disable the price if any of the limits are reached (product or price)
        if (limitsValidations && limitsValidations.length >= 1) {
            shouldDisableProduct = true;
            validationMsg = limitsValidations[0].message;
        }

        return {
            disableProduct: shouldDisableProduct,
            validationMsg
        };
    }

    /**
     * @param {CalculateTotalCostOptions} options
     * @returns {string}
     */
    calculateTotalCost = ({
        suitablePrice,
        showPeriod,
        availableAddonPrices,
        productName,
        isSubdirectory,
        prices,
        _verbose
    }) => {
        let totalCost = '0';

        if (suitablePrice) {
            if (isSubdirectory && availableAddonPrices?.length) {
                let addonPriceTotal = 0;
                const basePrice = monthlyPrice(suitablePrice?.cost, suitablePrice?.billing_period);

                availableAddonPrices.forEach((addon) => {
                    const addonPrice = monthlyPrice(addon?.cost, addon?.billing_period);
                    // Add the cost for the subdirectory to the shown price of the product
                    addonPriceTotal += addonPrice;
                });
                totalCost = formatNumber(basePrice + addonPriceTotal);
            } else {
                totalCost = formatNumber(monthlyPrice(suitablePrice?.cost, suitablePrice?.billing_period));
            }
        } else if (!suitablePrice && prices?.length >= 1) {
            // CASE: We're above the possible members limit for this product and no prices are available
            //       Just so we have a number to show (which is disabled anyway), find the highest price
            //       available and return that price instead.
            const fallBackPrice = ProductUtils.getFallbackPrice({prices, showPeriod});

            if (_verbose) {
                console.log(`[BMA] → fallback price for ${productName}:`, fallBackPrice?.stripe_price_id, fallBackPrice?.limits[0].value, ' members');
            }

            totalCost = formatNumber(monthlyPrice(fallBackPrice?.cost, fallBackPrice?.billing_period));
        }

        return totalCost;
    }
}

const productUtils = new ProductUtils();

export default productUtils;
