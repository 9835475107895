import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {addInvoiceDetails, getPartialBillingData} from '../../data/api';
import SpinnerButton from './SpinnerButton';

export default function UpdateInvoiceDetails({
    invoiceDetails,
    customerId,
    onCloseModal,
    setModalLoadingState
}) {
    const buttonStates = {
        success: {
            className: 'gh-btn gh-btn-block',
            html: '&#10004; Update complete!',
            type: 'submit',
            disabled: true
        },
        fail: {
            className: 'gh-btn gh-btn-block',
            html: 'Try again',
            type: 'submit',
            disabled: false
        },
        pending: {
            className: 'gh-btn gh-btn-block spinner',
            html: '<span><div class="gh-spinner"></div></span>',
            disabled: true
        },
        default: {
            className: 'gh-btn gh-btn-block',
            html: 'Update',
            type: 'submit',
            disabled: false
        }
    };

    const [newInvoiceDetails, setNewInvoiceDetails] = useState(invoiceDetails);
    const [errors, setErrors] = useState(null);
    const [buttonState, setButtonState] = useState({...buttonStates.default});

    const handleSubmit = async (event) => {
        event.preventDefault();

        setModalLoadingState(true);
        setButtonState({...buttonStates.pending});
        setErrors(null);

        const res = await addInvoiceDetails({
            customerId,
            invoiceDetails: newInvoiceDetails
        });

        if (res?.errors) {
            const message = res.errors?.message ?? 'Unable to update invoice details. Refresh the page and try again or contact support@ghost.org';

            setModalLoadingState(false);
            setErrors(message);
            return setButtonState({...buttonStates.fail});
        }

        await getPartialBillingData('invoiceDetails');

        // SUCCESS!
        setModalLoadingState(false);
        setButtonState({...buttonStates.success});
        return onCloseModal({reload: false, delay: 1000});
    };

    const handleInputChange = (event) => {
        setNewInvoiceDetails(event.target.value);
        setErrors(null);
        setButtonState({...buttonStates.default});
    };

    return (
        <form className="gh-form" onSubmit={handleSubmit}>
            <header>
                <h1>Update your invoice details.</h1>
            </header>

            <div className="gh-input-group">
                <label htmlFor="invoice-details">Billing info</label>
                <textarea
                    id="invoice-details"
                    className="gh-input"
                    name="name"
                    placeholder="Enter optional address or billing information to be displayed on invoices."
                    rows="5"
                    autoCorrect="off"
                    data-testid="input-invoice-details"
                    autoFocus
                    value={newInvoiceDetails}
                    onChange={event => handleInputChange(event)}
                />
            </div>

            <SpinnerButton {...buttonState} data-testid="update-invoice-details" />

            {errors &&
                <div className="form-error">
                    <p className="error-msg" data-testid="error-msg">{errors}</p>
                </div>
            }
        </form>
    );
}

UpdateInvoiceDetails.propTypes = {
    invoiceDetails: PropTypes.string,
    customerId: PropTypes.string.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    setModalLoadingState: PropTypes.func.isRequired
};
