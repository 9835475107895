import React, {useState} from 'react';
import PropTypes from 'prop-types';
import UpdateInvoiceDetails from './UpdateInvoiceDetails';
import ModalWrapper from './ModalWrapper';

export default function InvoiceDetailsModal({
    handleCloseModal,
    invoiceDetails,
    customerId,
    modalIsOpen,
    setModalIsOpen
}) {
    const [modalLoadingState, setModalLoadingState] = useState(false);

    return (
        <section className="staticfield">
            <header>
                <div className="staticfield-label">Invoice details</div>
                <ModalWrapper
                    setModalIsOpen={setModalIsOpen}
                    modalIsOpen={modalIsOpen}
                    onCloseModal={() => handleCloseModal({reload: false})}
                    title="Update invoice details"
                    openModalTrigger={<button className="staticfield-link" data-test-btn="open-invoice-details">Edit</button>}
                    isLoading={modalLoadingState}
                >
                    <UpdateInvoiceDetails
                        onCloseModal={handleCloseModal}
                        invoiceDetails={invoiceDetails}
                        customerId={customerId}
                        setModalLoadingState={setModalLoadingState}
                    />
                </ModalWrapper>
            </header>
            <div className="staticfield-value" dangerouslySetInnerHTML={{__html: invoiceDetails || '<span>Add optional address / billing info</span>'}}></div>
        </section>
    );
}

InvoiceDetailsModal.propTypes = {
    handleCloseModal: PropTypes.func.isRequired,
    invoiceDetails: PropTypes.string,
    customerId: PropTypes.string,
    modalIsOpen: PropTypes.bool,
    setModalIsOpen: PropTypes.func
};
