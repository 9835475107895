import React, {useState} from 'react';
import PropTypes from 'prop-types';
import StripeUpdateCard from './StripeUpdateCard';
import CardFields from './CardFields';
import ModalWrapper from '../shared/ModalWrapper';

export default function UpdateCardModal({
    user,
    customerId,
    isGrace,
    showCardUpdateModal,
    handleCloseModal,
    handleNestedLoadingState,
    setModalIsOpen,
    modalIsOpen
}) {
    const [modalLoadingState, setModalLoadingState] = useState(false);
    // track whether the user has been prompted to update their card
    const [wasPrompted, setWasPrompted] = useState(false);
    const openModalTrigger = (!wasPrompted && showCardUpdateModal) || (<button className="staticfield-link" data-test-btn="open-card">{isGrace ? 'Update' : 'Edit'}</button>);

    return (
        <section className="staticfield">
            <header>
                <div className="staticfield-label">Credit card</div>
                <ModalWrapper
                    openModalTrigger={openModalTrigger}
                    onCloseModal={() => {
                        setWasPrompted(true);
                        handleCloseModal({reload: false});
                    }}
                    title="Update credit card"
                    isLoading={modalLoadingState}
                    setModalIsOpen={setModalIsOpen}
                    modalIsOpen={modalIsOpen}
                    wasPrompted={wasPrompted}
                    setWasPrompted={setWasPrompted}
                >
                    <StripeUpdateCard
                        setLoadingState={handleNestedLoadingState}
                        onCloseModal={() => {
                            setWasPrompted(true);
                            handleCloseModal({reload: true, delay: 5000});
                        }}
                        userId={user?.id}
                        customerId={customerId}
                        setModalLoadingState={setModalLoadingState}
                    />
                </ModalWrapper>
            </header>

            <CardFields user={user} />
        </section>
    );
}

UpdateCardModal.propTypes = {
    user: PropTypes.shape({
        cardType: PropTypes.string,
        cardExpiry: PropTypes.string,
        cardLast4: PropTypes.string
    }).isRequired,
    customerId: PropTypes.string.isRequired,
    isGrace: PropTypes.bool,
    showCardUpdateModal: PropTypes.bool,
    handleCloseModal: PropTypes.func,
    handleNestedLoadingState: PropTypes.func,
    setModalIsOpen: PropTypes.func,
    modalIsOpen: PropTypes.bool
};
