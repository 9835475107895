import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ModalWrapper from '../shared/ModalWrapper';
import BillingContact from './BillingContact';

export default function BillingContactModal({
    user,
    setModalIsOpen,
    modalIsOpen,
    handleCloseModal
}) {
    const [modalLoadingState, setModalLoadingState] = useState(false);

    return (
        <section className="staticfield">
            <header>
                <div className="staticfield-label">Billing contact</div>
                <ModalWrapper
                    setModalIsOpen={setModalIsOpen}
                    modalIsOpen={modalIsOpen}
                    onCloseModal={() => handleCloseModal({reload: false})}
                    title="Update billing contact"
                    openModalTrigger={<button className="staticfield-link" data-test-btn="open-billing-contact">Edit</button>}
                    isLoading={modalLoadingState}
                >
                    <BillingContact
                        onCloseModal={handleCloseModal}
                        user={user}
                        setModalLoadingState={setModalLoadingState}
                    />
                </ModalWrapper>
            </header>
            <div className="staticfield-value">{user?.billing_contact || user?.email_address}</div>
        </section>
    );
}

BillingContactModal.propTypes = {
    user: PropTypes.shape({
        billing_contact: PropTypes.string,
        email_address: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired
    }).isRequired,
    setModalIsOpen: PropTypes.func.isRequired,
    modalIsOpen: PropTypes.bool.isRequired,
    handleCloseModal: PropTypes.func.isRequired
};
