import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    withRouter
} from 'react-router-dom';
import {getCachedBillingData} from '../data/api';

function Support(props) {
    const [isMounted, setIsMounted] = useState(false);
    const [, setBillingData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!isMounted) {
            if (props && props.showNav) {
                props.showNav(true);
            }

            props.updateTitle('Get help with your Ghost(Pro) account');
            setIsMounted(true);

            setBillingData(getCachedBillingData());
            setLoading(false);
        }
    }, [isMounted, props]);

    return (
        <>
            {loading ?
                (
                    <div className="gh-loading-content">
                        <div className="gh-loading-spinner"></div>
                    </div>
                ) :
                <main className='main'>
                    <div className='box'>
                        <div className='staticfield-label'>Need some help?</div>
                        <p>
                    You can get in touch with us anytime by sending an email
                    to <code>support@ghost.org</code>
                        </p>
                        <p>
                    Our team works Monday to Friday, and will get back to
                    you as soon as possible.
                        </p>
                    </div>
                </main>
            }
        </>
    );
}

Support.propTypes = {
    showNav: PropTypes.func,
    updateTitle: PropTypes.func
};

export default withRouter(Support);
