import React from 'react';
import PropTypes from 'prop-types';
import cloudIcon from '../../icons/cloud.svg';
import successIcon from '../../icons/success.svg';
import failedIcon from '../../icons/failed.svg';
import copyIcon from '../../icons/copy.svg';
import useCopyToClipboard from '../../hooks/useCopyToClipboard';

function DNSTable({
    dnsRecords,
    showStatus = false,
    showCloudflare = false,
    boxClass = 'domain-form',
    nameLabel = 'Name',
    children
}) {
    const [copiedValue, copy] = useCopyToClipboard();
    const success = <img src={successIcon} alt="Success" />;
    const failed = <img src={failedIcon} alt="Failed" />;

    return (
        <section id="step-2" className={boxClass}>
            <div className="dns-table">
                <div className="dns-table-content">
                    <p className="dns-table-key"><strong>Type</strong></p>
                    <p className="dns-table-name"><strong>{nameLabel}</strong></p>
                    <p className="dns-table-value"><strong>Value</strong></p>
                    {showCloudflare && <p className="dns-table-cfproxy"><strong>Proxy Status</strong></p>}
                    {showStatus && <p className="dns-table-status"><strong>Status</strong></p>}

                    {dnsRecords?.map(({record_type: record, name, value, valid}) => {
                        return (
                            <React.Fragment key={record + '-' + value}>
                                <p className="dns-table-key">{record}</p>
                                {name
                                    ?
                                    <p className="dns-table-name" onClick={() => copy(name)}>
                                        {name}
                                        {copiedValue && copiedValue === name
                                            ? <button className="copy-btn green"><img src={successIcon} alt="Copied" /></button>
                                            : <button className="copy-btn"><img src={copyIcon} alt="Copy" /></button>
                                        }
                                    </p>
                                    : <p>&nbsp;</p>
                                }
                                <p className="dns-table-value" onClick={() => copy(value)}>
                                    {value}
                                    {copiedValue && copiedValue === value
                                        ? <button className="copy-btn green"><img src={successIcon} alt="Copied" /></button>
                                        : <button className="copy-btn"><img src={copyIcon} alt="Copy" /></button>
                                    }
                                </p>
                                {showCloudflare && <p className="dns-table-cfproxy"><img className="max-h-16" src={cloudIcon} alt="DNS Only"/>DNS Only</p>}
                                {showStatus && <p className="dns-table-status">{valid === 'valid' ? success : failed}</p>}
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
            {children}
        </section>
    );
}

DNSTable.propTypes = {
    dnsRecords: PropTypes.arrayOf(PropTypes.shape({
        record_type: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.string,
        valid: PropTypes.oneOf(['valid', 'unknown'])
    })).isRequired,
    showStatus: PropTypes.bool,
    boxClass: PropTypes.string.isRequired,
    nameLabel: PropTypes.string,
    children: PropTypes.node
};

export default DNSTable;
