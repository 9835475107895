export const yearlyPrice = (price, basePeriod) => {
    return parseInt(price, 10) * (basePeriod === 'month' ? 12 : 1);
};

export const monthlyPrice = (price, basePeriod) => {
    return parseInt(price, 10) / (basePeriod === 'year' ? 12 : 1);
};

const priceForPeriod = {
    monthlyPrice,
    yearlyPrice
};

export default priceForPeriod;
