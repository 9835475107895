import React, {useState} from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import {cancelAccount, getSubscription} from '../../data/api';
import SpinnerButton from './SpinnerButton';

/**
 * @typedef {object} CancelImmediatelyProps
 * @property {boolean} isLoading
 * @property {object} billingData
 * @property {string} subscriptionId
 * @property {function} onCloseModal
 * @property {function} setLoadingState
 * @property {function} setErrors
 * @property {function} setIsLoading
 */

/**
 * @param {number} ms
 * @returns {Promise<void>}
 */
const sleep = (ms) => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
};

/**
 * @param {CancelImmediatelyProps} props
 * @returns {JSX.Element}
 */
function CancelImmediately({
    isLoading,
    billingData,
    subscriptionId,
    onCloseModal,
    setLoadingState,
    setErrors,
    setIsLoading
}) {
    const buttonStates = {
        success: {
            className: 'gh-btn gh-btn-block gh-btn-red',
            html: '&#10004; Successfully cancelled!',
            disabled: true
        },
        fail: {
            className: 'gh-btn gh-btn-block gh-btn-red',
            html: 'Contact support@ghost.org',
            disabled: true
        },
        pending: {
            className: 'gh-btn gh-btn-red gh-btn-block spinner',
            html: '<span><div class="gh-spinner"></div></span>',
            disabled: true
        },
        default: {
            className: 'gh-btn gh-btn-block gh-btn-red',
            html: 'Cancel subscription and delete all data',
            onSubmit: 'cancel',
            disabled: true
        }
    };
    const [buttonState, setButtonState] = useState(buttonStates.default);
    const [pollingInterval, setPollingInterval] = useState(null);
    const [cancelToggle, setCancelToggle] = useState(
        {
            download: false,
            deleted: false,
            recover: false
        }
    );

    const closeModalAndRedirect = () => {
        setLoadingState(true);

        // Reload the data, so going back in history wouldn't provide wrong information
        onCloseModal({reload: true, delay: 1000});

        // Redirect the user to ghost.org. This is like a 'fake' signing out process
        // because going back in browser history afterwards will already show the
        // offline.ghost.org page
        return window.parent.location.href = 'https://ghost.org';
    };

    /**
     * Takes the subscriptionId and checks if the subscription has been cancelled in Daisy
     * @param {{subscriptionId: string}} options
     * @returns {Promise<void>}
     */
    const checkSubscriptionCancelled = async ({subscriptionId: subId}) => {
        try {
            // Actually proceed with the cancellation which will also suspend the site
            const res = await getSubscription({
                subId,
                includeCancelled: true
            });

            if (res?.subscription) {
                if (res?.subscription?.attributes?.status === 'canceled') {
                    clearInterval(pollingInterval);
                    return closeModalAndRedirect();
                }
            }
        } catch (error) {
            Sentry.withScope((scope) => {
                scope.setExtra('subscriptionId', subId);
                scope.setTag('pointer', 'cancelModal_checkSubscriptionCancelled');
                scope.setContext('getSubscription', error);
                Sentry.captureException(error);
            });

            clearInterval(pollingInterval);
        }
    };

    /**
     * Handles the immediate cancellation of the account by cancelling the subscription
     * with the background API method and polling the subscription endpoint to check if
     * the subscription has been cancelled.
     * @param {React.MouseEvent<HTMLButtonElement>} event
     * @returns {Promise<void>}
     */
    const handleCancelImmediately = async (event) => {
        event.preventDefault();
        // Set loading state. Can only be done at this point, because the
        // Stripe card elements needs to be mounted until now
        setIsLoading(true);
        setButtonState(buttonStates.pending);

        try {
            // Actually proceed with the cancellation which will also suspend the site
            const res = await cancelAccount({subscriptionId});

            if (res?.ok) {
                // create a timeout to cancel the cancellation request after
                // 90 seconds and show and error to the user instead
                setTimeout(() => {
                    Sentry.withScope((scope) => {
                        scope.setExtra('subscriptionId', subscriptionId);
                        scope.setTag('pointer', 'handleCancelImmediately_cancelAccount');
                        Sentry.captureException(new Error(`Cancelling timed out`));
                    });

                    setIsLoading(false);
                    setButtonState(buttonStates.fail);
                    setErrors('Something went wrong when trying to cancel. Please refresh your browser and try again, or contact support@ghost.org');
                    if (pollingInterval) {
                        clearInterval(pollingInterval);
                    }
                }, 90 * 1000);

                // Wait for 2 seconds to give the subscription time to cancel
                sleep(2000);

                // Start polling the subscription endpoint to check
                // if the subscription has been cancelled
                return setPollingInterval(setInterval(async () => {
                    await checkSubscriptionCancelled({subscriptionId});
                }, 4 * 1000));
            } else {
                Sentry.withScope((scope) => {
                    scope.setExtra('subscriptionId', subscriptionId);
                    scope.setTag('pointer', 'handleCancelImmediately_cancelAccount');
                    scope.setContext('cancelAccount', res);
                    Sentry.captureException(new Error(`Cancelling failed`));
                });

                setIsLoading(false);
                setButtonState(buttonStates.fail);
                return setErrors('Something went wrong when trying to cancel. Please refresh your browser and try again, or contact support@ghost.org');
            }
        } catch (error) {
            Sentry.withScope((scope) => {
                scope.setUser({email: billingData?.user?.email_address});
                scope.setExtra('subscriptionId', subscriptionId);
                scope.setTag('pointer', 'handleCancelImmediately_cancelAccount');
                scope.setExtra('site', billingData?.currentSite?.url);
                Sentry.captureException(error);
            });

            setIsLoading(false);
            setButtonState(buttonStates.fail);
            return setErrors('Something went wrong when trying to cancel. Please refresh your browser and try again, or contact support@ghost.org');
        }
    };

    /**
     * Handles the toggling of the checkboxes and only enables the cancel button
     * when all checkboxes are checked
     * @param {React.ChangeEvent<HTMLInputElement>} event
     * @param {string} checkbox
     */
    const handleCancelToggle = (event, checkbox) => {
        cancelToggle[checkbox] = event.target.checked;
        setCancelToggle(cancelToggle);

        const checkBoxValues = Object.values(cancelToggle);

        // Only enable the cancel confirm button when all toggles are checked
        if (checkBoxValues.length >= 3 && checkBoxValues.every(val => val === true)) {
            setButtonState({...buttonStates.default, disabled: false});
        } else {
            setButtonState(buttonStates.default);
        }
    };

    /**
     * @param {React.MouseEvent<HTMLButtonElement>} event
     * @returns {Promise<void>}
     */
    const setSpinnerButtonAction = (event) => {
        if (buttonState?.disabled || !buttonState?.onSubmit) {
            return null;
        } else if (buttonState?.onSubmit === 'cancel') {
            return handleCancelImmediately(event);
        }
    };

    return (
        <>
            <div className="cancel-account-warning">
                <p>Here's what happens when you cancel:</p>
                <ul>
                    <li>Your subscription will stop and you won’t be billed again.</li>
                    <li>Your Ghost(Pro) account will be closed immediately.</li>
                    <li>Your account and publication data will be permanently deleted. Remember to export your data before you cancel.</li>
                </ul>
            </div>

            <div className="gh-input-group">
                <span className="toggle-inline">
                    <input
                        id="checkbox-download"
                        className="toggle-btn"
                        type="checkbox"
                        name="download"
                        disabled={isLoading}
                        onChange={e => handleCancelToggle(e, 'download')}
                    />
                    <label htmlFor="download">I have downloaded my data / don’t need my data</label>
                </span>
                <span className="toggle-inline">
                    <input
                        id="checkbox-deleted"
                        className="toggle-btn"
                        type="checkbox"
                        name="deleted"
                        disabled={isLoading}
                        onChange={e => handleCancelToggle(e, 'deleted')}
                    />
                    <label htmlFor="deleted">I understand that my data will be permanently deleted</label>
                </span>
                <span className="toggle-inline">
                    <input
                        id="checkbox-recover"
                        className="toggle-btn"
                        type="checkbox"
                        name="recover"
                        disabled={isLoading}
                        onChange={e => handleCancelToggle(e, 'recover')}
                    />
                    <label htmlFor="recover">I understand that my data cannot be recovered</label>
                </span>
            </div>

            <SpinnerButton
                onClick={event => setSpinnerButtonAction(event)}
                className={buttonState.className}
                html={buttonState.html}
                disabled={buttonState.disabled}
                data-test-btn="cancel-account-now"
            />
        </>
    );
}

CancelImmediately.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    billingData: PropTypes.object.isRequired,
    subscriptionId: PropTypes.string.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    setLoadingState: PropTypes.func.isRequired,
    setErrors: PropTypes.func.isRequired,
    setIsLoading: PropTypes.func.isRequired
};

export default CancelImmediately;
