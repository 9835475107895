import * as Sentry from '@sentry/react';
import errors from '@tryghost/errors';
import parentWindowDataChannel from '../data/ParentWindowDataChannel';

/**
 * @typedef {object} JWTokenHandler
 * @property {() => Promise<string|{errors: Error}>} init
 * @property {() => Promise<void>} ensureAccessToken
 * @property {string} jwt
 * @property {() => void} maintainSession
 * @property {() => void} clearSessionInterval
 */

/** @implements JWTokenHandler */
class JWTokenHandler {
    /** @type {string|null} */
    #jwt = null;

    /** @type {boolean} */
    #verbose = false;

    /** @type {NodeJS.Interval} */
    #intervalId = null;

    /** @type {number} */
    #sessionExpiry = null;

    /**
     * @param {{verbose?: boolean, interval?: number}} options
     * @constructor
     */
    constructor(options) {
        const defaultInterval = process.env.REACT_APP_ENVIRONMENT !== 'production'
            ? 1000 * 90 // 90 seconds
            : 1000 * 60 * 60 * 1; // 1 hour
        this.#verbose = options?.verbose ?? false;
        this.#sessionExpiry = options?.interval ?? defaultInterval;
    }

    /**
     * @returns {Promise<string|{errors: Error}>}
     */
    async init() {
        const scope = Sentry.getCurrentScope();
        scope.setTransactionName('JWTokenHandler');
        // Avoid fetching a new JWT, unless it's the initial request
        // or the existing JWT is expired.
        // Get the JWT from Ghost Admin client
        // This causes Ghost Admin to make a request to `/identities`
        this.#jwt = await parentWindowDataChannel.getToken();

        if (this.#jwt === null) {
            // User is not the owner and doesn't have permission to read the data.
            // Stop with any further action
            const error = new errors.UnauthorizedError({message: 'Only the site owner can access billing information.'});
            return {errors: error};
        }

        return await this.#fetchBillingSession({init: true});
    }

    /**
     * @private
     * @param {{init?: boolean}} options
     * @returns {Promise<string|{errors: Error}>}
     */
    async #fetchBillingSession({init = false}) {
        try {
            const res = await window.fetch('/api/billing-session', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    jwt: this.#jwt,
                    options: {
                        init,
                        verbose: this.#verbose
                    }
                })
            });

            const session = await res.json();

            // When the JWT is expired, we need to fetch a new one, which can be done
            // by passing the refresh option to this function
            if (session?.errors && session?.errors?.message === 'Token has expired') {
                Sentry.addBreadcrumb({
                    category: 'JWTokenHandler',
                    message: 'JWT expired',
                    level: 'info'
                });
                return await this.init();
            }

            if (session?.errors && session?.errors?.status === 403 && session?.errors?.message.match(/legacy plan/gmi)) {
                Sentry.addBreadcrumb({
                    category: 'JWTokenHandler',
                    message: `Legacy plan: ${session?.errors?.message}`,
                    level: 'info'
                });

                if (this.#verbose) {
                    console.log('Legacy', session.errors);
                }

                return {errors: session?.errors};
            } else if (session?.errors && session?.errors?.status === 403) {
                Sentry.addBreadcrumb({
                    category: 'JWTokenHandler',
                    message: `Unauthorized: ${session.errors}`,
                    level: 'info'
                });

                if (this.#verbose) {
                    console.log('Unauthorized', session.errors);
                }

                const error = new errors.UnauthorizedError({message: 'Only the site owner can access billing information.'});
                return {errors: error};
            } else if (session?.errors) {
                Sentry.addBreadcrumb({
                    category: 'JWTokenHandler',
                    message: `No permission: ${session.errors}`,
                    level: 'info'
                });

                if (this.#verbose) {
                    console.log('No permission', session.errors);
                }

                const error = new errors.NoPermissionError({message: 'Error receiving billing data, please contact support@ghost.org'});
                return {errors: error};
            }

            return this.#jwt;
        } catch (error) {
            Sentry.captureException(error, {tags: {pointer: 'JWTokenHandler'}});
            return {errors: error};
        }
    }

    /**
     * @description This method is used to ensure that the JWT is valid and not expired and the cookie is set
     * @returns {Promise<void>}
     */
    async ensureAccessToken() {
        await this.#fetchBillingSession({init: false});
    }

    /**
     * @readonly
     * @returns {string}
     */
    get jwt() {
        if (!this.#jwt) {
            return this.init()
                .then(jwt => jwt);
        }
        return this.#jwt;
    }

    /**
     * @description This method is used to maintain the session cookie by refreshing the JWT every 4 hours
     * @returns {void}
     */
    maintainSession = () => {
        if (this.#verbose) {
            console.log(`[API] maintainSession every ${this.#sessionExpiry / 1000} seconds`);
        }
        // Refresh the JWT every 4 hours
        this.#intervalId = setInterval(async () => {
            if (this.#verbose) {
                console.log('[API] Refreshing JWT', new Date().toISOString());
            }
            Sentry.addBreadcrumb({
                category: 'maintainSession',
                message: `Refreshing JWT`,
                level: 'info'
            });
            await this.ensureAccessToken();
        }, this.#sessionExpiry);
    };

    /**
     * @description This method is used to clear the session interval
     * @returns {void}
     */
    clearSessionInterval = () => {
        if (this.#verbose) {
            console.log('[API] Clear session interval');
        }
        if (this.#intervalId) {
            clearInterval(this.#intervalId);

            Sentry.addBreadcrumb({
                category: 'clearSessionInterval',
                message: `Cleared session maintaining interval`,
                level: 'info'
            });
        }
    };
}

export default JWTokenHandler;

