import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
    Link,
    withRouter
} from 'react-router-dom';
import {getSubdomain} from 'tldts';
import {
    getPartialBillingData,
    getCachedBillingData,
    updateCustomDomain,
    checkCustomDomain,
    checkDomainDns
} from '../../data/api';
import * as Sentry from '@sentry/react';

import SpinnerButton from '../shared/SpinnerButton';
import DNSTable from './DNSTable';
import DomainStatusField from './DomainStatusField';

let _isMounted = false;

function CustomDomain({
    updateTitle,
    history,
    showNav
}) {
    const finalStates = ['ACTIVE', 'FAILED', 'PROXY', 'CUSTOM', 'INVALID', 'DEFUNCT'];
    const failedStates = ['FAILED', 'INVALID', 'DEFUNCT'];
    const successStates = ['ACTIVE', 'PROXY', 'CUSTOM'];
    const pendingStates = ['PENDING', 'NONE', 'CUSTOMNEXT'];

    const noApexDomainNameservers = ['godaddy', 'hover', 'google'];

    // We should have four states of a submit button
    // remove when input value is empty -> shouldn't be allowed (black button disabled)
    // 1. update when new domain is different to old domain (black button)
    // 2. activate when new domain is entered and valid and no old domain exists (black button)
    // 3. try again when domain setup failed for whatever reason (red button)
    // 4. continue (which also fetches fresh subscription data) after a successful activation (green button)
    const buttonStates = {
        fail: {
            className: 'gh-btn gh-btn-block',
            html: 'Try again',
            onSubmit: 'handleSubmit',
            onBack: 'reloadAndBack',
            disabled: false
        },
        pending: {
            className: 'gh-btn gh-btn-block spinner',
            html: '<span><div class="gh-spinner"></div>Activation may take up to 10 minutes</span>',
            onBack: 'reloadAndBack',
            disabled: true
        },
        default: {
            className: 'gh-btn gh-btn-block',
            html: 'Activate',
            onSubmit: 'handleSubmit',
            disabled: true
        }
    };

    const [loading, setLoading] = useState(true);
    const [disableInput, setDisableInput] = useState(false);
    const [newDomain, setNewDomain] = useState('');
    const [buttonState, setButtonState] = useState(buttonStates.default);
    const [timeoutId, setTimeOutId] = useState(null);
    const [errors, setErrors] = useState(null);
    const [currentSite, setCurrentSite] = useState(null);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [proDomain, setProDomain] = useState(null);
    const [cname, setCname] = useState('www');
    const [showRemoveDomain, setShowRemoveDomain] = useState(false);
    const [showDomainStatusField, setShowDomainStatusField] = useState(false);
    const [backBtnDisabled, setBackBtnDisabled] = useState(false);
    const [nameserver, setNameserver] = useState('unknown');

    useEffect(() => {
        if (showNav && loading) {
            showNav(false);
        }
    }, [showNav, loading]);

    const reloadAndBack = async (event) => {
        event.preventDefault();

        if (timeoutId) {
            // Ensure to stop the polling
            clearTimeout(timeoutId);
        }

        setButtonState(buttonStates.default);
        setDisableInput(true);
        setLoading(true);
        setNewDomain('');

        showNav(false);

        const updatedSite = await getPartialBillingData('currentSite');

        setCurrentSite(updatedSite);
        setLoading(false);

        showNav(true);

        return history.push('/domain');
    };

    const handleSubmit = async (event) => {
        event?.preventDefault();

        setDisableInput(true);
        setButtonState(buttonStates.pending);
        setErrors(null);
        setBackBtnDisabled(true);

        if ((!newDomain || newDomain === currentSite?.external_domain) && !failedStates.includes(currentSite?.ssl_status)) {
            setErrors('Invalid custom domain');
            Sentry.withScope((scope) => {
                scope.setExtra('pointer', 'CustomDomain -> handleSubmit');
                scope.setExtra('currentSite', currentSite);
                Sentry.captureMessage('Invalid custom domain', 'warning');
            });
            setShowDomainStatusField(false);
            setButtonState(buttonStates.fail);
            setDisableInput(false);
            setBackBtnDisabled(false);
            return;
        }

        let finalDomain = newDomain;

        // Ensure nameserver is up-to-date before checking
        const nameserverRes = await checkDomainDns({domain: newDomain, siteId: currentSite?.id});
        if (nameserverRes.nameServer && noApexDomainNameservers.includes(nameserverRes.nameServer) && getSubdomain(newDomain) === '') {
            finalDomain = `www.${newDomain}`;
        }

        try {
            // When updating or activating a custom domain, we use a
            // background function which always returns a 202.
            // The error handling has to happen when we poll the site.
            await updateCustomDomain({
                siteId: currentSite?.id,
                domain: finalDomain
            });

            setTimeOutId(setTimeout(() => {
                pollSite({currentSite});
            }, 3000));
            return;
        } catch (error) {
            Sentry.captureException(error, {
                tags: {
                    pointer: 'handleSubmit'
                }
            });
        }
    };

    const handleRemoveDomain = async (event) => {
        event.preventDefault();

        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        setErrors(null);
        setLoading(true);

        const res = await updateCustomDomain({
            siteId: currentSite?.id,
            domain: null
        });

        if (res?.errors) {
            const message = res?.errors?.message ?? 'Unable to remove domain. Refresh the page and try again or contact support@ghost.org.';
            setErrors(message);
            setLoading(false);

            return;
        }

        return reloadAndBack(event);
    };

    const pollSite = async ({currentSite: site}) => {
        setCurrentSite(site);

        const res = await checkCustomDomain({
            siteId: site?.id
        });

        setBackBtnDisabled(false);

        if (res?.errors) {
            const message = res.errors?.message || 'We weren\'t able to activate your custom domain. Contact support@ghost.org';

            setDisableInput(false);
            setErrors(message);
            setShowDomainStatusField(false);
            setButtonState(buttonStates.fail);
            return;
        }

        const isDomainUpdate = currentSite?.external_domain && successStates.includes(res?.ssl_status) && res?.hostname !== newDomain;
        const isDomainActivation = !currentSite?.external_domain && !res?.hostname && newDomain && !currentSite?.ssl_status;

        // if the site has a custom domain and the new domain doesn't match
        // the now returned domain, the update process failed most likely due
        // to validation error, when the domain is already in use.
        if (isDomainActivation || isDomainUpdate) {
            const message = isDomainUpdate
                ? 'We weren\'t able to update your custom domain. Refresh the page and try again or contact support@ghost.org'
                : 'We weren\'t able to activate your custom domain. Refresh the page and try again or contact support@ghost.org';
            setDisableInput(false);
            setErrors(message);
            setShowDomainStatusField(false);
            setButtonState(buttonStates.fail);
            return;
        }

        // Allow the domain to be removed, once it's setup in the DB
        if (res?.ssl_status && res?.hostname) {
            if (res.hostname) {
                setCurrentSite({
                    ...site,
                    external_domain: res.hostname,
                    ssl_status: res.ssl_status
                });
            }
        }

        if (res?.ssl_status && finalStates.includes(res.ssl_status)) {
            setDisableInput(false);

            setShowRemoveDomain(successStates.includes(res.ssl_status) ? true : false);
            setShowDomainStatusField(successStates.includes(res.ssl_status) ? true : false);

            if (failedStates.includes(res.ssl_status)) {
                let message = 'We weren\'t able to activate your custom domain. Contact support@ghost.org.';

                if (res.ssl_status === 'INVALID') {
                    if (!cname) {
                        message = `Unable to detect a valid CNAME record for ${newDomain}. Did you mean to enter www.${newDomain}?`;
                    } else {
                        message = `Unable to detect a valid CNAME record for ${newDomain}.`;
                    }
                }
                setErrors(message);
                setButtonState(buttonStates.fail);

                Sentry.withScope((scope) => {
                    scope.setExtra('pointer', 'CustomDomain -> pollSite');
                    scope.setExtra('currentSite', site);
                    Sentry.captureMessage(message, 'warning');
                });
                return;
            }
        } else if (res?.ssl_status && pendingStates.includes(res?.ssl_status)) {
            // We're still going
            setTimeOutId(setTimeout(() => {
                pollSite({currentSite: site});
            }, 3000));
            return;
        } else {
            setErrors('Refresh the page to update the status of your domain activation.');
            Sentry.withScope((scope) => {
                scope.setExtra('pointer', 'CustomDomain -> pollSite');
                scope.setExtra('currentSite', site);
                Sentry.captureMessage('Refresh the page to update the status of your domain activation.', 'warning');
            });
            setShowDomainStatusField(false);
            setButtonState(buttonStates.fail);
            return;
        }
    };

    const handleDomainValidationOnBlur = (event) => {
        event.preventDefault();

        let isValid = true;

        let newDomainValue = event.target?.value && event.target.value.trim().toLowerCase();
        if (URL.canParse(newDomainValue)) {
            newDomainValue = new URL(newDomainValue).hostname;
            setNewDomain(newDomainValue);
        }

        const currentDomainValue = currentSite?.external_domain && currentSite?.external_domain.trim().toLowerCase();
        const domainRegex = /^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,13}$/i;
        let errorsValue = null;
        let buttonStateValue = {...buttonStates.default, disabled: false};

        if (!newDomainValue) {
            buttonStateValue.disabled = true;
            isValid = false;
        }

        if (currentDomainValue && newDomainValue) {
            if (currentDomainValue === newDomainValue) {
                setShowDomainStatusField(true);
                isValid = false;
            } else if (currentDomainValue !== newDomainValue) {
                setShowDomainStatusField(false);
            }
        }

        if (newDomainValue && !newDomainValue.match(domainRegex)) {
            buttonStateValue.disabled = true;
            errorsValue = 'Enter a valid domain';
            isValid = false;
        }

        if (newDomainValue && newDomainValue.endsWith('.' + proDomain)) {
            buttonStateValue.disabled = true;
            errorsValue = 'External domains should not end in ' + proDomain;
            isValid = false;
        }

        setCNAMEValue({domain: newDomainValue});
        setNewDomain(newDomainValue);

        checkDomainDns({
            domain: newDomainValue,
            siteId: currentSite?.id
        }).then((res) => {
            if (res.nameServer) {
                setNameserver(res.nameServer);
            } else {
                setNameserver('unknown');
            }

            setCNAMEValue({domain: newDomain, currentNameserver: res.nameServer});
        });

        setErrors(errorsValue);
        setButtonState(buttonStateValue);

        return isValid;
    };

    const setCNAMEValue = ({domain, currentNameserver = nameserver}) => {
        if (!domain) {
            setCname('www');
            return;
        }

        const subdomain = getSubdomain(domain);

        if (subdomain && subdomain.length >= 1) {
            // we have a subdomain
            setCname(subdomain);
        } else if (noApexDomainNameservers.includes(currentNameserver)) {
            // nameserver does not support root domain CNAME
            setCname('www');
        } else {
            // Root domain setup
            setCname('@');
        }
    };

    const handleDomainInputChange = (event) => {
        event.preventDefault();
        const newDomainValue = event.target?.value && event.target.value.trim().toLowerCase();
        const buttonStateValue = {...buttonState, disabled: false};

        if (!newDomainValue || newDomainValue?.length < 3) {
            buttonStateValue.disabled = true;
            setErrors(null);
        }

        setNewDomain(newDomainValue);
        setButtonState(buttonStateValue);
    };

    const contactSupport = (event) => {
        event.preventDefault();
        return window.location.href = 'mailto:support@ghost.org';
    };

    const setSpinnerButtonAction = (event) => {
        if (buttonState?.disabled || !buttonState?.onSubmit) {
            return null;
        } else if (buttonState?.onSubmit === 'reloadAndBack') {
            return reloadAndBack(event);
        } else if (buttonState?.onSubmit === 'handleSubmit') {
            return handleSubmit(event);
        }
    };

    useEffect(() => {
        if (!_isMounted) {
            _isMounted = true;

            const fetchCachedBillingData = async () => {
                const billingData = await getCachedBillingData();
                const {currentSite: site, isGrace, forceUpgradeOwner} = billingData;

                setProDomain(site?.pro_domain || 'ghost.io');

                if (!billingData || billingData?.errors) {
                    if (billingData?.errors?.status === 403) {
                        setShowError(true);
                        setErrorMessage(<>You’re on a special legacy plan, if you need to make any billing changes please reach out to us on <a href="mailto:support@ghost.org" onClick={contactSupport}>support@ghost.org</a></>);
                    } else {
                        return history.push('/');
                    }
                }

                const scope = Sentry.getCurrentScope();
                scope.setTransactionName('Custom Domain');
                Sentry.setUser({email: billingData?.user?.email_address});
                Sentry.setContext('billingData', {
                    status: billingData?.subscription?.status,
                    price: billingData?.currentPrice?.nickname,
                    site: site?.url,
                    exceededLimits: billingData?.exceededLimits,
                    forceUpgrade: billingData?.isForceUpgrade
                });
                Sentry.setTag('section', 'customDomain');

                // Site with a lapsed trial or subscription are in a `forceUpgrade` state.
                // If the current user is the owner of the site, redirect them to the
                // plan selection to checkout a new subscription.
                if (forceUpgradeOwner || isGrace) {
                    showNav(true);
                    // Return to Dashboard when user is in dunning to update card details
                    return history.push(forceUpgradeOwner ? '/plans' : '/');
                }

                setCurrentSite(site);

                setShowRemoveDomain(site?.external_domain && (successStates.includes(site?.ssl_status)) ? true : false);

                setNewDomain(site?.external_domain ?? '');

                if (site?.external_domain) {
                    setCNAMEValue({domain: site?.external_domain});
                }

                setLoading(false);

                if (pendingStates.includes(site?.ssl_status)) {
                    // If we're still in a pending state, continue with polling
                    setButtonState(buttonStates.pending);
                    setDisableInput(true);
                    setShowDomainStatusField(false);
                    setTimeOutId(setTimeout(() => {
                        pollSite({currentSite: site});
                    }, 3000));
                } else if (successStates.includes(site?.ssl_status)) {
                    setShowDomainStatusField(true);
                } else if (failedStates.includes(site?.ssl_status)) {
                    setButtonState(buttonStates.fail);
                    setDisableInput(false);
                    setShowDomainStatusField(false);
                } else {
                    setButtonState(buttonStates.default);
                    setShowDomainStatusField(false);
                    setDisableInput(false);
                }

                showNav(false);
            };

            updateTitle('Domain settings');
            fetchCachedBillingData();
        }

        return () => {
            _isMounted = false;
            if (timeoutId) {
                // Ensure to stop the polling
                clearTimeout(timeoutId);
            }
        };
    }, []); // eslint-disable-line

    const dnsRecords = [
        {
            record_type: 'CNAME',
            name: cname,
            value: `${currentSite?.subdomain}.${proDomain}`
        },{
            record_type: 'A',
            name: `${cname === '@' ? 'www' : '@'}`,
            value: '178.128.137.126'
        }
    ];

    if (cname && cname !== 'www' && cname !== '@') {
        // if we have a subdomain other than `www` we need to omit the last dns record
        // as there shouldn't be an A record setup for the root <-> www redirect.
        dnsRecords.pop();
    }

    return (
        <main className="main">

            <div className="domain" data-test-id="domain">

                {loading ?
                    (
                        <div className="gh-loading-content">
                            <div className="gh-loading-spinner"></div>
                        </div>
                    ) :
                    (showError) ?
                        (
                            <p style={{textAlign: 'center', gridColumn: '1 / 3'}}>{errorMessage}</p>
                        ) :
                        (
                            <div className="domaingrid">
                                <header className="domain-nav">
                                    {buttonState?.onBack ?
                                        backBtnDisabled ?
                                            // We need to wait until the first polling response, otherwise the external domain
                                            // is not set yet and we won't see it in the domain overview.
                                            <p className='link-back disabled'>&larr; Back</p>
                                            :
                                            <Link to="/domain" className='link-back' onClick={reloadAndBack}>&larr; Back</Link>
                                        :
                                        <Link to="/domain" className="link-back">&larr; Back</Link>
                                    }
                                    <div className="actions">
                                        <a className="gh-btn gh-btn-green gh-btn-sm" href="https://ghost.org/help/using-custom-domains/?ref=billing.ghost.org" target="_blank" rel="noopener noreferrer" data-test-btn="custom-domain-help">Get help</a>
                                    </div>
                                </header>

                                <div className="box-label">1. Enter the domain you want to use</div>
                                <section id="step-1" className="domain-form">
                                    <div className="">
                                        <div>
                                            <label htmlFor="domain">Domain</label>
                                            <input
                                                id="domain"
                                                className="gh-input"
                                                type="domain"
                                                required
                                                name="domain"
                                                placeholder="www.example.com"
                                                disabled={disableInput}
                                                autoCorrect="off"
                                                tabIndex="1"
                                                autoFocus={finalStates.includes(currentSite?.ssl_status) ? false : true}
                                                value={newDomain}
                                                onChange={event => handleDomainInputChange(event)}
                                                onBlur={event => handleDomainValidationOnBlur(event)}
                                                onKeyDown={(e) => {
                                                    if (e.code === 'Enter') {
                                                        if (handleDomainValidationOnBlur(e)) {
                                                            return handleSubmit(e);
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </section>

                                <div className="box-label">2. Create your DNS records</div>
                                <DNSTable
                                    dnsRecords={dnsRecords}
                                    showStatus={false}
                                    showCloudflare={nameserver === 'cloudflare'}
                                    boxClass="domain-form"
                                    nameLabel="Host"
                                >
                                </DNSTable>

                                <div className="box-label">3. Activate custom domain</div>
                                <section id="step-3" className='domain-form step-activate'>
                                    {!showDomainStatusField
                                        ?
                                        <SpinnerButton
                                            onClick={event => setSpinnerButtonAction(event)}
                                            className={buttonState.className}
                                            html={buttonState.html}
                                            disabled={buttonState.disabled}
                                            data-test-btn="activate-domain"
                                        />
                                        :
                                        <DomainStatusField
                                            status={currentSite?.ssl_status}
                                            domain={currentSite?.external_domain}
                                            type="custom-domain"
                                        />
                                    }

                                    {errors &&
                                        <div className="form-error">
                                            <p className="error-msg">{errors}</p>
                                        </div>
                                    }

                                    {showRemoveDomain &&
                                        <button className="domain-remove" data-test-btn="remove-domain" onClick={handleRemoveDomain}>Remove custom domain</button>
                                    }
                                </section>

                            </div>
                        )
                }

            </div>

        </main>
    );
}

CustomDomain.propTypes = {
    updateTitle: PropTypes.func,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }),
    showNav: PropTypes.func
};

export default withRouter(CustomDomain);
