import {findSuitableProduct} from './billing-utils';
import {findSuitablePrices} from '../utils/member-limits';

/**
 * @typedef {import('./billing-utils').SiteLimits} SiteLimits
 * @typedef {import('./billing-utils').FormattedProduct} FormattedProduct
 */

/**
 * @typedef {object} PendingSubscription
 * @property {string} product
 * @property {string} period
 * @property {string} priceId
 * @property {string} group
 */

/**
 * @typedef {object} PendingSubscriptionProps
 * @property {object} subscription
 * @property {boolean} isAutoBillingGrace
 * @property {boolean} isIncomplete
 * @property {SiteLimits} currentSiteLimits
 * @property {FormattedProduct[]} availableProducts,
 * @property {{show_product_group: string}} user
 */

/**
 * @description Get the pending subscription information from the local storage
 * and validate it against the current site limits and assigned product group.
 * If the pending subscription is not valid any more, it will be removed from the local storage.
 * For customers in an incomplete or autobilling grace state, the pending subscription
 * is the same as the current subscription, and we have to ensure it's stored in the local
 * storage.
 * @param {PendingSubscriptionProps} props
 * @returns {PendingSubscription|null}
 */
export default function getPendingSubscription(props) {
    const {
        subscription,
        isAutoBillingGrace,
        isIncomplete,
        currentSiteLimits,
        availableProducts,
        user
    } = props;
    /** @type {PendingSubscription} */
    let pendingSubscription = null;
    /** @type {string} */
    let storedSubscription = null;

    try {
        if (typeof window?.localStorage !== 'undefined') {
            storedSubscription = window.localStorage.getItem(`gh_checkout_${subscription?.customer_id}`);
        }
    } catch (error) {
        // noop
    }

    try {
    // The pending subscription is stored in the local storage, but it should be removed if
    // the product or price are not suitable any more based on the current site limits
    // and assigned product group, unless the customer is in an incomplete or autobilling grace
    // state, as both of those state have the required new subscription already assigned to the customer.
        if (storedSubscription) {
        /** @type {PendingSubscription} */
            const parsedLocalStorageData = JSON.parse(storedSubscription);
            /** @type {string} */
            const showProductGroup = user?.show_product_group;

            if (!isAutoBillingGrace && !isIncomplete) {
                let suitableProduct = null;
                let suitablePrice = null;
                // verify the pending subscription still matches with what the user can have
                /** @type {string} */
                const group = parsedLocalStorageData?.group;
                /** @type {string} */
                const period = parsedLocalStorageData?.period;
                /** @type {string} */
                const priceId = parsedLocalStorageData?.priceId;
                /** @type {string} */
                const product = parsedLocalStorageData?.product?.toLowerCase();

                if (showProductGroup === group) {
                    const currentBaseProduct = availableProducts.find((availableProduct) => {
                        return availableProduct.name.toLowerCase() === product && availableProduct.group === group;
                    });
                    // get the suitable price for the current product and member usage
                    const suitablePrices = findSuitablePrices(currentBaseProduct?.prices, currentSiteLimits?.members?.total, period);
                    suitablePrice = suitablePrices?.[0];
                    suitableProduct = availableProducts.find(availableProduct => availableProduct?.name.toLowerCase() === product);
                } else {
                    // get the suitable product
                    const suitableProductAndPrice = findSuitableProduct(availableProducts, currentSiteLimits, period, showProductGroup);
                    suitableProduct = suitableProductAndPrice?.product;
                    suitablePrice = suitableProductAndPrice?.price;
                }

                if (suitableProduct && suitablePrice) {
                    const isProductMismatch = suitableProduct.name.toLowerCase() !== product;
                    const isPeriodMismatch = suitablePrice.billing_period !== period;
                    const isPriceMismatch = suitablePrice.stripe_price_id !== priceId;
                    const isGroupMismatch = suitableProduct.group !== group;

                    if (isProductMismatch || isPeriodMismatch || isPriceMismatch || isGroupMismatch) {
                    // Case: The pending subscription is not valid any more, remove it from local storage
                        try {
                            if (typeof window?.localStorage !== 'undefined') {
                                window.localStorage.removeItem(`gh_checkout_${subscription?.customer_id}`);
                            }
                        } catch (error) {
                        // noop
                        }

                        return null;
                    } else {
                        // Case: stored pending subscription is still valid
                        pendingSubscription = {
                            product,
                            period,
                            priceId,
                            group
                        };
                    }
                }
            } else {
                pendingSubscription = {
                    product: parsedLocalStorageData?.product,
                    period: parsedLocalStorageData?.period,
                    priceId: parsedLocalStorageData?.priceId,
                    group: parsedLocalStorageData?.group
                };
            }
        } else {
            /** @type {string} */
            const baseProduct = subscription?.meta?.baseProduct;
            // For both states we can extract the pending subscription information from the
            // current subscription information

            if (isAutoBillingGrace || isIncomplete) {
                pendingSubscription = {
                    product: baseProduct?.name.toLowerCase(),
                    period: baseProduct?.billing_period,
                    priceId: baseProduct?.stripe_price_id,
                    group: baseProduct?.group
                };

                try {
                    if (typeof window?.localStorage !== 'undefined') {
                        window.localStorage.setItem(`gh_checkout_${subscription?.customer_id}`, JSON.stringify(pendingSubscription));
                    }
                } catch (error) {
                // noop
                }
            }
        }
    } catch (error) {
        console.log('Error', error);
    }

    return pendingSubscription;
}
