/**
 * @description Generates the increment steps for member limits based on the available products
 * @param {object[]} availableProducts
 * @returns {number[]} incrementSteps
 */
export const getIncrementSteps = (availableProducts) => {
    if (!availableProducts.length) {
        throw new Error('No available products provided');
    }
    // programmatically generate the increment steps from the available products
    // the result should be the same as the incrementSteps array
    const incrementSteps = [...new Set(availableProducts.reduce((acc, product) => {
        const productPrices = product.prices.map((price) => {
            const [membersLimit] = price.limits.filter(limit => limit.name === 'members');
            return membersLimit ? membersLimit.value : null;
        }).filter(value => value !== null);
        return acc.concat(productPrices);
    }, []))].sort((a, b) => a - b);

    // Push the highest possible value to the end
    // This is not reflected in any price, but needed to show the highest possible value
    incrementSteps.push(400000);

    return incrementSteps;
};
/**
 * @description Returns the closest limit of members increments
 * We filter out values lower than current members value, order and return first entry
 * @param {number} current
 * @param {object[]} availableProducts
 * @returns {number} returnValue
 */
export const findNearestLimit = (current = 0, availableProducts = []) => {
    let incrementSteps = 500;

    if (!availableProducts?.length) {
        return incrementSteps;
    }

    try {
        incrementSteps = getIncrementSteps(availableProducts);
    } catch (error) {
        // noop
    }

    const returnValue = incrementSteps.filter((step) => {
        return step >= current;
    }).sort((a, b) => {
        return a - b;
    })[0];

    if (!returnValue && current > incrementSteps[incrementSteps.length - 1]) {
        return incrementSteps[incrementSteps.length - 1];
    }

    return returnValue;
};

/**
 * Returns an Array of available prices that is equal or higher the passed in members limit and matches the billing period
 * @param {object[]} availablePrices
 * @param {number} membersSelected
 * @param {string} periodSelected
 * @returns {object[]} Array of suitable prices, sorted by their members limit
 */
export const findSuitablePrices = (availablePrices, membersSelected, periodSelected) => {
    if ((!availablePrices || !availablePrices.length) || (!membersSelected && !membersSelected <= 0) || !periodSelected) {
        throw new Error('Arguments must be provided');
    }

    return availablePrices
        // ensure the available prices are sorted by members limit first
        .sort(({limits: a}, {limits: b}) => {
            const [aMembers] = a.filter(limit => limit.name === 'members');
            const [bMembers] = b.filter(limit => limit.name === 'members');

            return aMembers.value - bMembers.value;
        })
        // return all prices where the billing period matches the currently selected one and the
        // member limits are equal or greater than the selected limit
        .filter((price) => {
            const isCorrectPeriod = price.billing_period === periodSelected;
            const isCorrectMembersLimit = price.limits.filter((limit) => {
                if (limit.name === 'members') {
                    return limit.value >= membersSelected;
                }
                return false;
            });
            return isCorrectPeriod && isCorrectMembersLimit.length;
        });
};

const memberLimits = {
    getIncrementSteps,
    findNearestLimit,
    findSuitablePrices
};

export default memberLimits;
