const financial = (x) => {
    return Number.parseFloat(x).toFixed(2);
};

/**
 * Format a number 1000000 into 1,000,000
 */

const formatNumber = (val, {fixed, symbol} = {}) => {
    if (!val && val !== 0) {
        return;
    }

    if (fixed) {
        // Always return 2 fixed decimal places
        val = financial(val);
    } else {
        // test if we have a decimal with a value not 0 and ensure we have at least 2 decimal places
        if (val.toString().match(/\.\d+/)) {
            val = financial(val);
        }
        // Remove `.00` unless we said otherwise (fixed:true).
        // Won't remove e. g. `.12`
        val = val.toString().replace(/(\d*)(\.0+)$/, '$1');
    }

    val = val.toString();

    // Pass in a symbol, such as a currency icon. Any negative sign (`-`) will be
    // prefixed, e. g. '-$12.34', but percentage signs will be appended, e. g. '12%'
    if (symbol) {
        if (symbol === '%') {
            val = val + symbol;
        } else {
            val = symbol + val;
        }

        const patternString = '(\\' + symbol + '?)(-?)';
        const symbRegex = new RegExp(patternString);
        val = val.replace(symbRegex, '$2$1');
    }

    const pattern = /(-?\d+)(\d{3})/;

    while (pattern.test(val)) {
        val = val.replace(pattern, '$1,$2');
    }

    return val;
};

export default formatNumber;
