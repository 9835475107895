import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';

export default function HeaderNav({showNav}) {
    return (
        <nav className={`pro-nav ${!showNav ? ' hidden' : ''}`}>
            <NavLink
                className="pro-nav-item"
                to="/"
                activeClassName="active"
                isActive={(match, location) => {
                    if (location.pathname.match(/domain/i)) {
                        return false;
                    }
                    if (location.pathname.match(/support/i)) {
                        return false;
                    }
                    return true;
                }}
            >
                Billing
            </NavLink>
            <NavLink className="pro-nav-item" to="/domain" strict activeClassName="active">Domain</NavLink>
            <NavLink className="pro-nav-item" to="/support" strict activeClassName="active">Support</NavLink>
        </nav>
    );
}

HeaderNav.propTypes = {
    showNav: PropTypes.bool.isRequired
};
