import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import 'react-responsive-modal/styles.css';
import {Modal} from 'react-responsive-modal';

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {React.ReactNode?} props.openModalTrigger
 * @param {function?} props.setModalIsOpen
 * @param {boolean?} props.modalIsOpen
 * @param {function?} props.onCloseModal
 * @param {string} props.title
 * @param {boolean?} props.isLoading
 */
export default function ModalWrapper(props) {
    let openModalElement = null;
    const [open, setOpen] = useState(false);

    const onOpenModal = useCallback(
        () => {
            if (!open) {
                setOpen(true);

                if (props?.setModalIsOpen && typeof props.setModalIsOpen === 'function') {
                    props.setModalIsOpen(true);
                }

                if (!props?.wasPrompted
                && props?.setWasPrompted
                && typeof props.setWasPrompted === 'function'
                ) {
                // track whether the user has been prompted to update their card
                    props.setWasPrompted(true);
                }
            }
        },
        [open, props]
    );

    const onCloseModal = () => {
        if (props?.isLoading) {
            return;
        }

        if (open) {
            setOpen(false);

            if (props?.onCloseModal && typeof props.onCloseModal === 'function') {
                // if the close modal prop is a function, run this instead
                return props.onCloseModal();
            }

            if (props?.setModalIsOpen && typeof props.setModalIsOpen === 'function') {
                props.setModalIsOpen(false);
            }

            if (props?.setWasPrompted && typeof props.setWasPrompted === 'function') {
                props.setWasPrompted(false);
            }
        }
    };

    useEffect(() => {
        if (props?.modalIsOpen && !open) {
            setOpen(true);
        } else if (!props?.modalIsOpen && open) {
            setOpen(false);
        }
    }, [props?.modalIsOpen, open]);

    // Handle a force prompt to open the modal
    useEffect(() => {
        if (typeof props.openModalTrigger === 'boolean' && props.openModalTrigger === true) {
            if (!open && !props?.wasPrompted) {
                onOpenModal();
            }
        }
    }, [props?.openModalTrigger, open, props?.wasPrompted, onOpenModal]);

    if (props?.openModalTrigger) {
        if (React.isValidElement(props.openModalTrigger)) {
            openModalElement = React.cloneElement(props.openModalTrigger, {onClick: onOpenModal});
        }
    }

    const modalContainerRef = React.useRef(null);

    const closeIcon = (
        <svg version="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.707 12L23.854.854a.5.5 0 0 0-.707-.707L12 11.293.854.146a.5.5 0 0 0-.707.707L11.293 12 .146 23.146a.5.5 0 0 0 .708.708L12 12.707l11.146 11.146a.5.5 0 1 0 .708-.706L12.707 12z" /></svg>
    );

    return (
        <>
            <div ref={modalContainerRef} className="gh-modal-content"/>
            {openModalElement}
            <Modal
                open={open}
                onClose={onCloseModal}
                aria-labelledby={props.title}
                modalId={props.title.trim().toLowerCase().replace(/\s/g, '-')}
                center
                focusTrapped={false}
                closeIcon={closeIcon}
                container={modalContainerRef.current}
                classNames={{
                    overlay: 'gh-modal-overlay',
                    modal: 'gh-modal',
                    closeButton: 'gh-modal-close'
                }}
                closeOnEsc={!props?.isLoading ?? true}
                closeOnOverlayClick={!props?.isLoading ?? true}
                {...props}
            >
                {props.children}
            </Modal>
        </>
    );
}

ModalWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    openModalTrigger: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.bool
    ]),
    setModalIsOpen: PropTypes.func,
    modalIsOpen: PropTypes.bool,
    onCloseModal: PropTypes.func,
    title: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    wasPrompted: PropTypes.bool,
    setWasPrompted: PropTypes.func
};
