import formatNumber from '../utils/format-number';

const processMemberLimits = (limits) => {
    const limitWordings = [];

    if (limits && limits.length) {
        const memberLimits = limits.filter(limit => limit.name = 'members')[0];

        if (parseInt(memberLimits.value, 10) >= 1000000) {
            limitWordings.push(`Up to ${parseInt(memberLimits.value, 10) / 1000000}M members`);
        } else {
            limitWordings.push(`Up to ${formatNumber(memberLimits.value)} members`);
        }
    }

    return limitWordings;
};

const processAddon = (addon) => {
    if (addon.name.toLowerCase().indexOf('subdirectory') >= 0) {
        return {
            ...addon,
            name: 'Subdirectory install', // Update the addon name
            description: 'If you have an existing site — for example a shop, or a static site hosted elsewhere — Ghost can be run from a separate location e.g. <code>yourdomain.com/blog</code> otherwise known as a subdirectory. <a href="https://ghost.org/help/can-i-run-ghost-from-a-subdirectory/?ref=billing.ghost.org" target="_blank" rel="noopener noreferrer">Learn more</a>.'
        };
    }

    return addon;
};

const legacyFeatures = {
    Starter: [
        '25k views/month',
        '1 staff user',
        'Up to 1,000 members',
        'SSL+CDN included'
    ],
    Basic: [
        '100k views/month',
        '2 staff users',
        'Custom themes',
        'Custom integrations'
    ],
    Standard: [
        '500k views/month',
        '5 staff users',
        '8,000 members',
        'Priority support'
    ],
    Business: [
        '1M views/month',
        'Unlimited staff users',
        '35,000 members',
        '99.9% uptime SLA'
    ]
};

const productFeatures = {
    Starter: [
        '1 staff user',
        'Free, official Ghost themes',
        'Standard built-in integrations'
    ],
    Creator: [
        'Everything in Starter',
        '2 staff users',
        'Unlimited custom themes',
        '1,000+ Zapier integrations',
        'Build custom integrations'
    ],
    Team: [
        'Everything in Creator',
        '5 staff users',
        'Priority support',
        'More business tools'
    ],
    Business: [
        'Everything in Team',
        'Unlimited staff users',
        'Advanced domain configs',
        '99.9% uptime SLA'
    ]
};

export default function hydrateProduct(product) {
    const hydratedProduct = Object.assign({}, product);

    if (!hydratedProduct.name) {
        return hydratedProduct;
    }

    // 1. Process base product limits
    if (hydratedProduct.legacy) {
        let planType = hydratedProduct?.nickname?.split(' ')[0];
        if (legacyFeatures[planType] && legacyFeatures[planType].length) {
            hydratedProduct.features = legacyFeatures[planType];
        }
    } else if (productFeatures[hydratedProduct.name]) {
        hydratedProduct.features = productFeatures[hydratedProduct.name];
    } else {
        // If we don't know about this product, still return an empty array
        hydratedProduct.features = [];
    }

    // 2. Process member limits in prices
    if (hydratedProduct.prices && hydratedProduct.prices.length) {
        // limits per product in available products
        hydratedProduct.prices.forEach((price) => {
            price.features = processMemberLimits(price.limits);
        });
    } else if (hydratedProduct?.base_product && hydratedProduct?.limits) {
        // limit for the current price
        hydratedProduct.features = processMemberLimits(hydratedProduct.limits);
    }

    // 3. Process product addons
    if (hydratedProduct.addons && hydratedProduct.addons.length) {
        hydratedProduct.addons = hydratedProduct.addons.map(addon => processAddon(addon));
    }

    return hydratedProduct;
}
