/**
 * Formats the word 'month' or 'year' into 'Monthly' or 'Yearly'
 */
const formatPeriod = (period) => {
    if (!period) {
        throw new Error('No period string to format passed');
    }

    return `${period.charAt(0).toUpperCase()}${period.length >= 2 ? period.slice(1) : ''}ly`;
};

export default formatPeriod;
