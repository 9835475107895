import React, {useState} from 'react';
import PropTypes from 'prop-types';
import formatNumber from '../../utils/format-number';

import ModalWrapper from '../shared/ModalWrapper';

function Invoice(invoice, i) {
    const label = invoice?.status === 'pending' ? '<strong>Pending</strong>' : 'Paid';
    const localeOptions = {year: 'numeric', month: 'short', day: 'numeric'};
    const formattedDate = new Date(invoice.date).toLocaleString('en-UK', localeOptions);

    return (
        <a key={i} className="invoices-item" href={invoice.url} target="_blank" rel="noopener noreferrer">
            <div className="invoice-desc">{formattedDate} — {label}</div>
            <div className="invoice-price">
                <div className="invoice-amount">{formatNumber(invoice.paid, {symbol: '$'})}</div>
                <div className="invoice-arrow">→</div>
            </div>
        </a>
    );
}

Invoice.propTypes = {
    date: PropTypes.string,
    paid: PropTypes.number,
    status: PropTypes.string,
    url: PropTypes.string
};

export default function Invoices({invoices}) {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    return (
        <div className="box-invoices box-wrap">
            <div className="box-label">Billing history</div>
            <div className="box">
                <section className="staticfield">
                    <header>
                        <div className="staticfield-label">Invoices</div>
                        {invoices.length >= 5 &&
                            <ModalWrapper
                                setModalIsOpen={setModalIsOpen}
                                modalIsOpen={modalIsOpen}
                                title="All invoices"
                                openModalTrigger={<button className="staticfield-link">View all</button>}
                            >
                                <header>
                                    <h1>Invoices.</h1>
                                </header>
                                {invoices.map((invoice, i) => <Invoice key={i} {...invoice} />)}
                            </ModalWrapper>
                        }
                    </header>
                    <div className="invoices">
                        {invoices.slice(0, 5).map((invoice, i) => <Invoice key={i} {...invoice} />)}
                    </div>
                </section>
            </div>
        </div>
    );
}

Invoices.propTypes = {
    invoices: PropTypes.arrayOf(PropTypes.shape({
        date: PropTypes.string,
        paid: PropTypes.number,
        status: PropTypes.string,
        url: PropTypes.string
    }))
};
