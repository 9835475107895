import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

const defaultProps = {
    className: 'gh-btn gh-btn-block spinner',
    html: 'Submit',
    disabled: false
};

export default function SpinnerButton(props) {
    // closure action
    let handleSubmit = () => {};

    if (props?.onSubmit && typeof props.onSubmit === 'function') {
        handleSubmit = props.onSubmit;
    }

    const [className, setClassName] = useState(props.className ?? defaultProps.className);
    const [html, setHtml] = useState(props.html ?? defaultProps.html);
    const [disabled, setDisabled] = useState(props.disabled ?? defaultProps.disabled);

    useEffect(() => {
        setClassName(props.className ?? defaultProps.className);
        setHtml(props.html ?? defaultProps.html);
        setDisabled(props.disabled ?? defaultProps.disabled);
    }, [props]);

    return (
        <button
            className={className}
            disabled={disabled}
            onClick={event => handleSubmit(event)}
            dangerouslySetInnerHTML={{__html: html}}
            {...props}
        />
    );
}

SpinnerButton.propTypes = {
    className: PropTypes.string,
    html: PropTypes.string,
    disabled: PropTypes.bool,
    onSubmit: PropTypes.func
};
