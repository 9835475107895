import isEqual from 'lodash/isEqual';
import {
    monthlyPrice,
    yearlyPrice
} from './price-for-period';
import formatNumber from './format-number';
import {findSuitablePrices} from './member-limits';
import checkLimits from './check-limits';

/**
 *
 * @param {object} price
 * @param {object[]} price.limits
 * @param {string} price.limits.name
 * @param {number} price.limits.value
 *
 * @returns {number} membersIncluded
 */
export function getMembersIncluded(price) {
    const membersIncluded = price.limits.find((limit) => {
        if (limit.name === 'members') {
            return limit.value;
        }
        return null;
    });

    return membersIncluded?.value ?? 0;
}

/**
 *
 * @param {object[]} availablePrices
 * @param {object[]} availablePrices.limits
 * @param {string} availablePrices.billing_period
 * @param {object} selectedPrice
 * @param {string} selectedPrice.billing_period
 * @returns {object} comparablePrice
 */
export function findComparablePrice(availablePrices, selectedPrice) {
    const comparablePrice = availablePrices.find((price) => {
        const sameLimits = isEqual(selectedPrice?.limits, price?.limits);
        const oppositePeriod = price?.billing_period !== selectedPrice?.billing_period;

        return sameLimits && oppositePeriod;
    });

    return comparablePrice;
}

/**
 *
 * @param {object} comparePrice
 * @param {number} comparePrice.cost
 * @param {string} comparePrice.billing_period
 * @param {object} suitablePrice
 * @param {number} suitablePrice.cost
 * @param {string} suitablePrice.billing_period
 * @returns {number}
 */
export function getMonthlyEquivalentPrice(comparePrice, suitablePrice) {
    return Math.max(monthlyPrice(suitablePrice.cost, suitablePrice.billing_period), monthlyPrice(comparePrice.cost, comparePrice.billing_period));
}

/**
 *
 * @param {object} comparePrice
 * @param {number} comparePrice.cost
 * @param {string} comparePrice.billing_period
 * @param {object} suitablePrice
 * @param {number} suitablePrice.cost
 * @param {string} suitablePrice.billing_period
 * @param {object} options
 * @param {boolean} options.percentage
 * @returns {number|null}
 */
export function getAnnualDiscount(comparePrice, suitablePrice, options) {
    const annualBasePrice = getMonthlyEquivalentPrice(comparePrice, suitablePrice) * 12;
    const currentAnnualPrice = yearlyPrice(suitablePrice.cost, suitablePrice.billing_period);
    let diff = 0;

    if (options?.percentage) {
        diff = Math.round((annualBasePrice - currentAnnualPrice) * 100 / annualBasePrice);
    } else {
        diff = annualBasePrice - currentAnnualPrice;
    }

    if (diff > 0) {
        return diff;
    } else {
        return null;
    }
}

/**
 * @typedef {object} StripeCoupon
 * @property {string} id
 * @property {string} object
 * @property {number} amount_off
 * @property {number} created
 * @property {string} currency // only if amount_off has value
 * @property {'forever'|'once'|'repeating'} duration
 * @property {number} duration_in_months // only if duration is repeating
 * @property {boolean} livemode
 * @property {number} max_redemptions
 * @property {object} metadata
 * @property {string} name
 * @property {number} percent_off
 * @property {string} redeem_by
 * @property {number} times_redeemed
 * @property {boolean} valid
 */
/**
 * @typedef {object} CouponDiscountData
 * @property {StripeCoupon} coupon required
 * @property {number|null} currentTotal optional, required if calculateTotal is true
 * @property {boolean} calculateTotal optional, default true. If false, returns the discount value as a percentage or fixed amount
 * @property {boolean} format optional, default false. If true, returns the discount value as a formatted string
 */
/**
 * @param {CouponDiscountData} data
 */
export function getCouponDiscount({coupon, currentTotal = null, calculateTotal = true, format = false}) {
    let discount = 0;
    let symbol = '$';

    if (!coupon) {
        return discount;
    }

    // We need at least one of these to calculate the coupon discount
    if (!coupon?.percent_off && !coupon?.amount_off) {
        return discount;
    }

    // For a percentage based coupon, we need a total to calculate the discount
    if (calculateTotal && (!currentTotal || typeof currentTotal !== 'number') && coupon?.percent_off) {
        return discount;
    }

    if (coupon?.percent_off) {
        // When we're calculating the total, we won't return the percentage value
        // as a percentage, but as a fixed amount and therefore need to change the symbol
        // to a currency symbol
        if (!calculateTotal) {
            symbol = '%';
        }
        discount = calculateTotal ? currentTotal * coupon.percent_off / 100 : coupon.percent_off;
    } else {
        discount = coupon?.amount_off / 100;
    }

    return format ? formatNumber(discount, {symbol}) : discount;
}

/**
 * @typedef {object} TotalPriceData
 * @param {number} cost
 * @param {'year'|'month'} period
 * @param {object[]} selectedAddons
 * @param {object[]} selectedAddons.prices
 * @param {number} selectedAddons.prices.cost
 * @param {string} selectedAddons.prices.billing_period
 * @param {StripeCoupon} coupon
 */
/**
 * @param {TotalPriceData} data
 * @returns {number}
 */
export function getTotalPrice({cost, period, selectedAddons = [], coupon = {}}) {
    let selectedAddonsCost;

    if (selectedAddons?.length) {
        const selectedAddonsPrices = selectedAddons.map((addonsSelected) => {
            return addonsSelected.prices.filter((price) => {
                return price.billing_period === period;
            })[0];
        });

        // add up all addon prices
        if (selectedAddonsPrices?.length) {
            selectedAddonsCost = selectedAddonsPrices.reduce((total, price) => {
                return total + price.cost;
            }, 0);
        }
    }

    const addons = selectedAddonsCost || 0;
    const basePlan = cost;
    const currentTotal = addons + basePlan;
    const couponDiscount = getCouponDiscount({coupon, currentTotal}) || 0;

    return currentTotal - couponDiscount;
}

/**
 * @typedef {object} Limit
 * @property {string} name
 * @property {number} value
 * @property {boolean} on_downgrade
 * @property {boolean} pro_only_feature
 */

/**
 * @typedef {object} AddonProduct
 * @property {string} name
 * @property {'addon'} type
 * @property {boolean} active
 * @property {string} metadata
 * @property {Price[]} prices
 * @property {Limit[]} limits
 */

/**
 * @typedef {object} FormattedLimit
 * @property {string} name
 * @property {number} value
 */

/**
 * @typedef {object} FormattedPrice
 * @property {number} cost
 * @property {string} [stripe_price_id]
 * @property {'year'|'month'} billing_period
 * @property {boolean} active
 * @property {Limit[]} limits
 * @property {string} nickname
 * @property {string} base_product
 * @property {boolean} legacy
 * @property {string} group
 */

/**
 * @typedef {object} FormattedProduct
 * @property {string} name
 * @property {'base'} type
 * @property {boolean} active
 * @property {number} order
 * @property {string} metadata
 * @property {string} group
 * @property {FormattedPrice[]} prices
 * @property {AddonProduct[]} addons
 * @property {FormattedLimit[]} limits
 * @property {string} stripeId
 */

/**
 * @typedef {object} SiteLimitProperties
 * @property {boolean} exceeded
 * @property {number} limit
 * @property {number?} total
 * @property {string?} active
 * @property {boolean?} hasOfficialTheme
 */

/**
 * @typedef {Object.<string, SiteLimitProperties>} SiteLimits
 */

/**
 *
 * @param {FormattedProduct[]} availableProducts
 * @param {SiteLimits} currentSiteLimits
 * @param {string} [period]
 * @param {string} [productGroup]
 * @returns {{product: FormattedProduct, price: FormattedPrice}|null}
 */
export function findSuitableProduct(availableProducts, currentSiteLimits, period = 'year', productGroup) {
    let result = null;
    const currentMembers = currentSiteLimits?.members?.total;
    const toValidate = [];

    if (!availableProducts?.length || !currentSiteLimits) {
        return result;
    }

    if (productGroup) {
        availableProducts = getPurchasableProducts(availableProducts, productGroup);
    }

    availableProducts.forEach((product) => {
        const prices = product.prices;
        const suitableMembersPrices = findSuitablePrices(prices, currentMembers, period);
        if (suitableMembersPrices.length) {
            toValidate.push({
                product: product,
                suitableMembersPrices
            });
        }
    });

    if (toValidate.length) {
        let foundValidProduct = false;

        // find the first product that has valid prices and return this from this function
        while (!foundValidProduct && toValidate.length) {
            const {product: productData, suitableMembersPrices} = toValidate.shift();

            for (const price of suitableMembersPrices) {
                const limitsValidations = checkLimits(price, {productLimits: productData.limits, currentSiteLimits, product: productData.name});

                if (!limitsValidations.length) {
                    foundValidProduct = true;
                    result = {
                        product: productData,
                        price: price
                    };
                    break;
                }
            }
        }
    }

    return result;
}

/**
 * @description Removes all products and sorts them by their order value that are not
 * in the purchasable group incl. the trial product.
 * @param {FormattedProduct[]} availableProducts
 * @param {string} productGroup
 * @returns {FormattedProduct[]}
 */
export function getPurchasableProducts(availableProducts, productGroup) {
    if (!availableProducts?.length) {
        return [];
    }

    return availableProducts
        .filter(product => product?.name?.toLowerCase() !== 'personal' && product?.name?.toLowerCase() !== 'trial')
        .filter((product) => {
            if (!product?.group || !productGroup) {
                return true;
            }
            return product.group === productGroup;
        })
        .sort((a, b) => a.order - b.order) ?? [];
}
