/**
 * Hashes a user's email address so we can use it as a distinct_id in PostHog without storing the email address itself
 * 
 * @param {string} email an email address
 * @returns {(string|null)} a sha256 hash of the email address to use as distinct_id in PostHog — null if hashing fails
 */
async function hashEmail(email) {
    try {
        const digest = await window.crypto.subtle.digest('SHA-256', new TextEncoder().encode(email.trim().toLowerCase()));
        const hashArray = Array.from(new Uint8Array(digest));
        const hash = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        // Double-check that the hash is a valid sha256 hex string before returning it, else return null
        return hash.length === 64 ? hash : null;
    } catch (e) {
        // Modern browsers all support window.crypto, but we need to check for it to avoid errors on really old browsers
        // If any errors occur when hashing email, return null
        return null;
    }
}

/**
 * Utility function to safely call posthog.identify() with a user's hashed email address in a single function call.
 * 
 * Accepts an email address, hashes it, then calls posthog.identify() with the hashed email address (or does nothing if hashing fails)
 * 
 * @param {string} email an email address
 * @returns {void}
 */
export async function identifyUser(posthog, email) {
    // Return early if posthog is not available or email is null/undefined
    if (!posthog || !email) {
        return;
    }
    const hashedEmail = await hashEmail(email);
    if (hashedEmail) {
        const distinctId = posthog?.get_distinct_id();
        if (distinctId !== hashedEmail) {
            posthog?.identify(hashedEmail);
        }
    }
}