import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import formatNumber from '../../utils/format-number';
import formatPeriod from '../../utils/format-period';
import {
    fromUnixTime,
    differenceInDays
} from 'date-fns';
import {getCouponDiscount} from '../../utils/billing-utils';
import CancelAtPeriodEndWarning from './CancelAtPeriodEndWarning';

// use a global variable to store the total price per period
// and avoid changing the price on re-renders
let _totalPricePerPeriod = 0;

function AddonItem({
    addon
}) {
    const [addonItem, setAddonItem] = useState({...addon});

    useEffect(() => {
        // Subdirectory needs to be renamed for the UI
        if (addonItem?.name.toLowerCase().indexOf('subdirectory') >= 0) {
            setAddonItem({name: 'Subdirectory install', cost: addonItem.cost});
        }
        _totalPricePerPeriod += addonItem?.cost;
    }, [addonItem?.cost, addonItem?.name]);

    return (
        <div className="invoices-item">
            <div className="invoice-desc">{addonItem.name}</div>
            <div className="invoice-price">{formatNumber(addonItem.cost, {symbol: '$'})}</div>
        </div>
    );
}

AddonItem.propTypes = {
    addon: PropTypes.shape({
        name: PropTypes.string.isRequired,
        cost: PropTypes.number.isRequired
    }).isRequired
};

function AddOns({
    currentPrice,
    addonItems,
    isSubdirectory
}) {
    return (
        <>
            {!isSubdirectory ?
                <div className="plandetails-breakdown">
                    <div className="staticfield-label">Price breakdown</div>
                    <div className="invoices-item">
                        <div className="invoice-desc">{currentPrice.base_product} base plan</div>
                        <div className="invoice-price">{formatNumber(currentPrice.cost, {symbol: '$'})}</div>
                    </div>

                    {addonItems.map(({...addon}, i) => (
                        <AddonItem
                            key={i}
                            addon={addon}
                        />
                    ))}
                </div>
                :
                <div className="plandetails-breakdown">
                    <div className="staticfield-label">Price breakdown</div>
                    <div className="invoices-item">
                        <div className="invoice-desc">{currentPrice.base_product} base plan</div>
                        <div className="invoice-price">{formatNumber(currentPrice.cost, {symbol: '$'})}</div>
                    </div>
                    <div className="invoices-item">
                        <div className="invoice-desc">Subdirectory install (incl. in base plan)</div>
                    </div>
                </div>
            }
        </>
    );
}

AddOns.propTypes = {
    currentPrice: PropTypes.shape({
        base_product: PropTypes.string,
        cost: PropTypes.number
    }),
    addonItems: PropTypes.array,
    isSubdirectory: PropTypes.bool
};

function PendingWarning({
    pendingSubscription,
    checkoutRoute
}) {
    return (
        <p className="red">Your last plan change to <strong>{`${pendingSubscription.product[0].toUpperCase()}${pendingSubscription.product.substring(1)}`} ({formatPeriod(pendingSubscription.period)}) isn't active</strong> yet. Click <Link to={checkoutRoute}><strong>here</strong></Link> to complete the payment now.</p>
    );
}

PendingWarning.propTypes = {
    pendingSubscription: PropTypes.shape({
        product: PropTypes.string,
        period: PropTypes.string
    }).isRequired,
    checkoutRoute: PropTypes.string.isRequired
};

function StaffLimitWarning({
    staffLimits
}) {
    return (
        <p className="red">Your plan includes {staffLimits.limit} staff users. You're currently using: {staffLimits.total}. <a href="https://ghost.org/docs/staff/?ref=billing.ghost.org" target="_blank" rel="noopener noreferrer">Plan limit details</a></p>
    );
}

StaffLimitWarning.propTypes = {
    staffLimits: PropTypes.shape({
        limit: PropTypes.number,
        total: PropTypes.number
    }).isRequired
};

/**
 * @typedef {object} DiscountSectionProps
 * @property {object} discount
 * @property {number} discount.end
 * @property {number} discount.start
 * @property {object} discount.coupon
 * @property {string} discount.coupon.id
 * @property {number} discount.coupon.percent_off
 * @property {number} discount.coupon.amount_off
 */

/**
 * @param {DiscountSectionProps} discount
 * @returns {JSX.Element}
 */
function DiscountSection({discount}) {
    if (discount?.coupon) {
        const endDate = discount?.end ? new Date(fromUnixTime(discount.end)) : null;
        const startDate = discount?.start ? new Date(fromUnixTime(discount.start)) : null;
        const today = new Date();
        const daysLeft = endDate ? differenceInDays(endDate, today) : 0;
        const hasStartedYet = startDate ? differenceInDays(startDate, today) >= 0 : true;
        const discountText = `-${getCouponDiscount({coupon: discount.coupon, format: true, calculateTotal: false})}`;

        // Three different types of coupons: forever, once, repeating
        // We need to check if the coupon is still valid and if it has started yet
        // We also need to check if the coupon is still valid if it's a repeating coupon
        const isValidForever = discount?.coupon?.duration === 'forever' && hasStartedYet && discount.coupon.valid;
        const isValidOnce = discount?.coupon?.duration === 'once' && hasStartedYet && discount.coupon.valid;
        const isStillValid = discount?.coupon?.duration === 'repeating' && hasStartedYet && daysLeft > 0 && discount.coupon.valid;

        if (isValidForever || isValidOnce || isStillValid) {
            return (
                <div>
                    <strong>Special offer active</strong>
                    <strong className="green">{discountText}</strong>
                </div>
            );
        } else {
            return null;
        }
    } else {
        return null;
    }
}

DiscountSection.propTypes = {
    discount: PropTypes.shape({
        end: PropTypes.number,
        start: PropTypes.number,
        coupon: PropTypes.shape({
            id: PropTypes.string,
            percent_off: PropTypes.number,
            amount_off: PropTypes.number,
            duration: PropTypes.string,
            duration_in_months: PropTypes.number
        })
    })
};

/**
 * @typedef {object} PlanDetailsProps
 * @property {object} currentSiteLimits
 * @property {object} currentPrice
 * @property {object} subscriptionMeta
 * @property {boolean} isSubdirectory
 * @property {object} pendingSubscription
 * @property {boolean} isPending
 * @property {string} checkoutRoute
 * @property {boolean} isGrace
 * @property {object} discount
 * @property {number} customerBalance
 * @property {string} currentPeriodEnd
 * @property {boolean} willCancelAtPeriodEnd
 */

/**
 * @param {PlanDetailsProps} props
 * @returns {JSX.Element}
 */
export default function PlanDetails({
    currentSiteLimits,
    currentPrice,
    subscriptionMeta,
    isSubdirectory,
    pendingSubscription,
    isPending,
    checkoutRoute,
    isGrace,
    discount,
    customerBalance,
    currentPeriodEnd,
    willCancelAtPeriodEnd,
    closeAndReload,
    subscriptionId
}) {
    const showPendingWarning = isPending && pendingSubscription?.product && checkoutRoute;
    _totalPricePerPeriod = currentPrice?.cost ?? 0;

    // We don't want the automatic price change add-on to appear in the plan details as such
    const addonItems = subscriptionMeta?.addons?.filter(addon => addon?.name?.toLowerCase()?.indexOf('autobilling') < 0);

    return (
        <>
            {currentPrice?.base_product &&
                <div className="box-plandetails box-wrap">
                    <div className="box-label">Your plan details</div>
                    <div className="box">
                        <div className="plandetails">
                            <div className="plandetails-info">
                                <div className="plandetails-content">
                                    <h2>{currentPrice.base_product} ({currentPrice?.billing_period && formatPeriod(currentPrice.billing_period)})</h2>
                                    {!willCancelAtPeriodEnd && currentPrice?.features &&
                                        <p>Base plan with {currentPrice?.features?.filter(feature => feature.indexOf('Everything') < 0).join(', ').toLowerCase()}</p>
                                    }
                                </div>

                                {!willCancelAtPeriodEnd &&
                                    <div>
                                        <Link className="gh-btn gh-btn-green plandetails-cta" to="/plans" disabled={isGrace} data-test-btn="update-plan">Update plan &rarr;</Link>
                                    </div>
                                }

                            </div>

                            {(showPendingWarning || currentSiteLimits?.staff?.exceeded) && !willCancelAtPeriodEnd &&
                                <div className="plandetails-warning">
                                    {(showPendingWarning) &&
                                        <PendingWarning
                                            pendingSubscription={pendingSubscription}
                                            checkoutRoute={checkoutRoute}
                                        />
                                    }
                                    {currentSiteLimits?.staff?.exceeded &&
                                        <StaffLimitWarning
                                            staffLimits={currentSiteLimits?.staff}
                                        />
                                    }
                                </div>
                            }

                            {willCancelAtPeriodEnd &&
                                <CancelAtPeriodEndWarning
                                    willCancelAtPeriodEnd={willCancelAtPeriodEnd}
                                    currentPeriodEnd={currentPeriodEnd}
                                    closeAndReload={closeAndReload}
                                    subscriptionId={subscriptionId}
                                />
                            }

                            {addonItems?.length >= 1 &&
                                <AddOns
                                    currentPrice={currentPrice}
                                    addonItems={addonItems}
                                    isSubdirectory={isSubdirectory}
                                />
                            }

                            <footer>
                                <div>
                                    <strong>Total price per {currentPrice?.billing_period}</strong>
                                    <strong>{_totalPricePerPeriod && formatNumber(_totalPricePerPeriod, {symbol: '$'})}</strong>
                                </div>
                                <DiscountSection discount={discount} />
                                {customerBalance && customerBalance <= 0 &&
                                    <div>
                                        <strong>Account credit balance</strong>
                                        <strong className={customerBalance < 0 ? 'green' : ''}>{formatNumber(customerBalance, {symbol: '$'})}</strong>
                                    </div>
                                }
                            </footer>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

PlanDetails.propTypes = {
    currentSiteLimits: PropTypes.shape({
        staff: PropTypes.shape({
            limit: PropTypes.number,
            total: PropTypes.number,
            exceeded: PropTypes.bool
        })
    }),
    currentPrice: PropTypes.shape({
        base_product: PropTypes.string,
        billing_period: PropTypes.string,
        cost: PropTypes.number,
        features: PropTypes.array
    }),
    subscriptionMeta: PropTypes.shape({
        baseProduct: PropTypes.shape({
            stripe_price_id: PropTypes.string
        }),
        addons: PropTypes.array
    }),
    isSubdirectory: PropTypes.bool,
    pendingSubscription: PropTypes.shape({
        product: PropTypes.string,
        period: PropTypes.string
    }),
    isPending: PropTypes.bool,
    checkoutRoute: PropTypes.string,
    isGrace: PropTypes.bool,
    discount: PropTypes.shape({
        end: PropTypes.number,
        start: PropTypes.number,
        coupon: PropTypes.shape({
            id: PropTypes.string,
            percent_off: PropTypes.number,
            amount_off: PropTypes.number,
            duration: PropTypes.string,
            duration_in_months: PropTypes.number
        })
    }),
    customerBalance: PropTypes.number,
    currentPeriodEnd: PropTypes.string,
    willCancelAtPeriodEnd: PropTypes.bool,
    closeAndReload: PropTypes.func,
    subscriptionId: PropTypes.string
};
